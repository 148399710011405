<template>
	<div></div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
	name: 'RunnitsSCSS',
});
</script>

<style lang="scss">
	.runnit-settings {
		&-row {
			display: flex;
			flex-direction: column;
			width: 100%;
			background: var(--grey-915);
			border-radius: 16px;
			padding: 15px;
			margin: 10px 0 0 0;
		}

		&-label {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 0 0 12px 0;
			width: 100%;

			&-text {
				font-size: 0.9rem;
				color: var(--grey-100);
				font-weight: bold;
			}

			&-tooltip {
				display: flex;
				padding: 0 0 0 7px;
			}
		}

		&-actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
		}
	}
	.runnit-node-type-image {
		background: rgba(255, 255, 255, 0.29);
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 15px 0 0;
		padding: 4px;
		border-radius: 12px;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);

		& img {
			border-radius: 12px;
			object-fit: cover;
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
			width: 50px;
			height: 50px;
		}
	}
	.runnit-page-title {
		font-size: 26px;
		font-weight: bold;
		color: white;
		margin: 35px 0 55px 0;
	}
	.add-another-runnit {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px;
		min-height: 460px;
		height: calc(100% - 36px);
		z-index: 1;
		background: rgba(255, 255, 255, 0.09);
		border-radius: var(--global-border-radius);
		transition: var(--transition-default);
		border: 3px dashed rgba(255, 255, 255, 0.19);

		&-icon {
			color: var(--grey-600) !important;
		}

		&:hover {
			cursor: pointer;
			background: rgba(255, 255, 255, 0.19);
			border: 3px dashed rgba(255, 255, 255, 0.29);

			& .add-another-runnit-icon {
				color: var(--grey-400) !important;
			}
		}
	}
	.temp-runnits-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 12px 0 6px 0;
		padding: 7px 12px 7px 15px;
		background: rgba(0, 0, 0, 0.4);
		border-radius: 12px;

		&-title {
			color: white;
			font-weight: bold;
			font-size: 26px;
		}
	}
</style>
