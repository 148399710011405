
import Vue from 'vue';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _trim from 'lodash/trim';
import { mapActions, mapState } from 'vuex';
import { db, functions } from '@/firebase';
import { v4 as uuidv4 } from 'uuid';
import {
	sliderValueMap,
	sliderValueReverseMap,
	sliderThumbLabelMap,
	sliderTicksLabels,
	sliderTicksLabelsMobile,
} from './constants';
import { Workspace } from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import ListItem from '@/components/base/ListItem.vue';
import BaseDrawer from '@/components/base/BaseDrawer.vue';
import InfoAssetBanner from '@/components/base/InfoAssetBanner.vue';
import DrawerSettingsRow from '@/components/base/DrawerSettingsRow.vue';
import DrawerSettingsContainer from '@/components/base/DrawerSettingsContainer.vue';
import InfoMessageSmall from '@/components/base/InfoMessageSmall.vue';
import WorkspacePricePreview from '@/views/Workspaces/WorkspacePricePreview.vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';

export default Vue.extend({
	name: 'AddEditWorkspace',
	props: {
		workspace: { type: Object, default: null },
		isEditMode: { type: Boolean, default: false },
		value: { type: Boolean, default: false },
	},
	data () {
		return {
			open: false,
			name: null,
			description: null,
			selectedStorageAmount: 2,
			isSharedWorkspace: true, // TODO: determine if this is something people want
			savingWorkspace: false,
			userFolders: false,
			individualFoldersWarningDialogOpen: false,

			sliderValueMap: sliderValueMap,
			sliderValueReverseMap: sliderValueReverseMap,
			sliderThumbLabelMap: sliderThumbLabelMap,
			sliderTicksLabels: sliderTicksLabels,
			sliderTicksLabelsMobile: sliderTicksLabelsMobile,

			// FORM
			formValid: true,

			deletingSpace: false,
			confrimDeleteOpen: false,
		};
	},
	created () {
		this.open = !!this.value;
		if (this.isEditMode) {
			this.initWorkspace(this.workspace);
		}
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
		workspace (newVal: Workspace) {
			if (newVal) {
				this.initWorkspace(newVal);
			}
		},
	},
	computed: {
		...mapState([
			'team',
			'user',
		]),
		computedRules () {
			let currSliderValueBottomBound: number = 1;

			if (this.isEditMode && this.workspace) {
				currSliderValueBottomBound = this.sliderValueReverseMap[this.workspace.storageBytesCap] || 1;
			}

			return {
				required: [
					v => !!_trim(v) || 'Required',
				],
				storageAmount: [
					v => v >= currSliderValueBottomBound || 'Cannot decrease size (contact support)',
				],
			};
		},
		isIncreasingStorageEditMode () {
			return !!(
				this.isEditMode &&
				this.workspace &&
				this.workspace.storageBytesCap < this.computedSelectedStorageValue
			);
		},
		shouldEnableAdd () {
			return !!this.formValid;
		},
		computedStorageThumbLabel () {
			return this.sliderThumbLabelMap[this.selectedStorageAmount];
		},
		computedSelectedStorageValue () {
			return this.sliderValueMap[this.selectedStorageAmount];
		},
		cantChangeFolderAccessOption () {
			return !!(
				this.isEditMode &&
				_get(this.workspace, 'userFolders')
			);
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		initWorkspace (workspace) {
			this.name = workspace ? workspace.name : null;
			this.description = workspace ? workspace.description : null;
			this.selectedStorageAmount = (workspace && this.sliderValueReverseMap[workspace.storageBytesCap]) || 2;
			this.userFolders = _has(workspace, 'userFolders') ? workspace.userFolders : false;
		},
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onSelectIndividualFolders () {
			this.userFolders = true;
			this.individualFoldersWarningDialogOpen = true;
		},
		closeWorkspaceDrawer () {
			this.setOpen(false);
			this.resetWorkspaceDrawer();
			this.$emit('on-close');
		},
		resetWorkspaceDrawer () {
			this.name = null;
			this.description = null;
			this.selectedStorageAmount = 2;
		},
		async onEditWorkspace () {
			if (!this.$refs.form.validate()) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}

			try {
				this.savingWorkspace = true;

				const onError: Function = (e) => {
					console.error('Error updating workspace: ', e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error! Problem with updating a Space, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				};

				const functionRef = functions
					.httpsCallable('updateWorkspace');

				const { success } = (await functionRef({
					description: _trim(this.description) || null,
					name: _trim(this.name),
					storageBytesCap: this.computedSelectedStorageValue,
					workspaceId: this.workspace.id,
					userFolders: this.userFolders,
				})).data;

				if (!success) {
					onError(new Error('updateWorkspace returned undefined response'));
				} else {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Space changes saved.`,
						show: true,
					});
					this.closeWorkspaceDrawer();
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error! Problem editing the Space, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			} finally {
				this.savingWorkspace = false;
			}
		},
		async onBuyWorkspace () {
			if (!this.$refs.form.validate()) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}

			try {
				this.savingWorkspace = true;
				const onError: Function = (e) => {
					console.error('Error adding a workspace: ', e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error! Problem with adding a Space, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				};
				const functionRef = functions
					.httpsCallable('createWorkspace');

				const { success } = (await functionRef({
					createdAt: new Date(),
					description: _trim(this.description) || null,
					name: _trim(this.name),
					uuid: uuidv4(),
					teamId: this.team.id,
					storageBytesCap: this.computedSelectedStorageValue,
					userFolders: this.userFolders,
				})).data;

				if (!success) {
					onError(new Error('createWorkspace returned undefined response'));
				} else {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! ${this.name} Space added.`,
						show: true,
					});

					// Refresh the correct list that was deleted from
					// if (isPending) {
					// 	await this.loadInitialPendingTeamMembers();
					// } else {
					// 	await this.loadInitialTeamMembers();
					// }
				}

				this.$emit('on-buy-success');
				this.closeWorkspaceDrawer();
			} catch (e) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Error adding Space, try again or open a ticket in Discord',
					show: true,
				});
			} finally {
				this.savingWorkspace = false;
			}
		},
		setConfirmDelete (isOpen) {
			this.confrimDeleteOpen = isOpen;
			if (!isOpen) {
				this.$refs.DoubleConfirmDeleteSpace.reset();
			}
		},
		async handleDeleteSpace () {
			try {
				this.deletingSpace = true;
				const deletedAt = new Date();
				const workspaceRef = db.doc(`workspaces/${this.workspace.id}`);
				await workspaceRef
					.update({
						deletedAt,
						deletedByUserId: this.user.id,
					});
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Successfully deleted space: ${this.name}`,
					show: true,
				});
				this.$emit('on-delete-success', {...this.workspace, deletedAt});
				this.closeWorkspaceDrawer();
			} catch (err) {
				console.error('Error deleting workspace:', err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error deleting space: ${this.name}`,
					show: true,
				});
			} finally {
				this.deletingSpace = false;
				this.setConfirmDelete(false);
			}
		},
	},
	components: {
		ConfirmDialog,
		BorderSelectCard,
		WorkspacePricePreview,
		InfoMessageSmall,
		ListItem,
		DrawerSettingsRow,
		DrawerSettingsContainer,
		InfoAssetBanner,
		BaseDrawer,
		DoubleConfirmDangerDialog,
	},
});
