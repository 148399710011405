
import Vue from 'vue';
import { mapState } from 'vuex';
import AddBalanceFundsButton from '@/components/AddBalanceFundsButton.vue';
import { MyBalanceMixin, TeamUserMixin } from '@/mixins';
import { SELECTED_STORAGE_TYPE } from '@/constants/constants';
import BalanceDetailsDialog from '@/components/BalanceCard/BalanceDetailsDialog.vue';
import SaveYourRecurringBalanceAlert from '@/components/BalanceCard/SaveYourRecurringBalanceAlert.vue';

export default Vue.extend({
	name: 'BalanceCard',
	mixins: [
		MyBalanceMixin,
		TeamUserMixin,
	],
	props: {
		styles: { type: String, default: null },
		selectionEnabled: { type: Boolean, default: false },
		selectedStorageType: { type: String, default: null },
	},
	data () {
		return {
			detailsDialogData: {
				open: false,
				scrollToPrivateBalance: false,
				scrollToTeamBalance: false,
			},
		};
	},
	computed: {
		...mapState([
			'team',
			'userBalanceAccount',
			'teamBalanceAccount',
		]),
		shouldShowLabels () {
			return !!(
				this.team &&
				!this.isOnboardingTeam
			);
		},
		teamSelected () {
			return this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM;
		},
	},
	methods: {
		openDetailsDialog (scrollToPrivateBalance: boolean, scrollToTeamBalance: boolean) {
			this.detailsDialogData = {
				open: true,
				scrollToPrivateBalance,
				scrollToTeamBalance,
			};
		},
		closeDetailsDialog () {
			this.detailsDialogData = {
				open: false,
				scrollToPrivateBalance: false,
				scrollToTeamBalance: false,
			};
		},
	},
	components: {
		SaveYourRecurringBalanceAlert,
		BalanceDetailsDialog,
		AddBalanceFundsButton,
	},
});
