
import Vue from 'vue';
import { mapState } from 'vuex';
import DiscordSVG from '@/assets/DiscordSVG.vue';
import { DISCORD_INVITE_LINK } from '@/utils';

export default Vue.extend({
	name: 'JoinOurDiscord',
	props: {
		breakpointAtMd: { type: Boolean, default: false },
		callToAction: { type: Boolean, default: true },
		secondary: { type: Boolean, default: false },
		boldBtnText: { type: Boolean, default: true },
	},
	data () {
		return {
			dialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'user',
		]),
		longFormBtnText () {
			return this.breakpointAtMd ? this.$vuetify.breakpoint.mdAndUp : this.$vuetify.breakpoint.smAndUp;
		},
		discordNotLinked () {
			return !!(
				this.longFormBtnText &&
				this.user &&
				!this.user.discordUser &&
				this.user.club
			);
		},
	},
	components: {
		DiscordSVG,
	},
	methods: {
		onJoinClick () {
			if (this.user && !this.user.discordUser && this.user.club) {
				this.dialogOpen = true;
			} else {
				this.onJoinServer();
			}
		},
		onJoinServer () {
			window.open(DISCORD_INVITE_LINK, '_blank').focus();
		},
		onLinkDiscord () {
			if (this.user) {
				const applicationId: string = '1098056685727252510';
				const redirectUri: string = 'https://app.rundiffusion.com/discord-callback';
				const responseType: string = 'token';
				const scope: string = 'identify';
				window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${applicationId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&scope=${encodeURIComponent(scope)}`;
			}
		},
	},
});
