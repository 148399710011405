import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-single-select"},[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field}}),(_vm._get(_vm.field, 'display.images'))?_c('div',{staticClass:"runnit-images-select"},[_c('menu-or-bottom-sheet',{attrs:{"max-width":600,"nudge-bottom":50},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [(_vm.localValue)?_c('div',_vm._g({staticClass:"single-select-image-options-activator"},on),[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"single-select-image-options-activator-image"},[_c('img',{attrs:{"alt":_vm.localValue.label,"src":_vm._get(_vm.localValue, 'image')}})]),_c('div',{staticClass:"single-select-image-options-activator-label"},[_vm._v(" "+_vm._s(_vm.localValue.label)+" ")])]),_c('div',{staticClass:"single-select-image-options-activator-icon"},[_c(VIcon,{staticStyle:{"color":"var(--grey-550)","font-size":"26px"}},[_vm._v("mdi-chevron-down-circle")])],1)]):_vm._e()]}},{key:"content",fn:function({ close }){return [(_vm.tempItems.length)?_c('div',{staticClass:"single-select-image-option-container",style:({
						width: _vm.$vuetify.breakpoint.smAndUp ? '375px' : '100%',
						padding: _vm.$vuetify.breakpoint.smAndUp ? '8px' : '15px 8px 20px 8px',
						borderTopLeftRadius: _vm.$vuetify.breakpoint.smAndUp ? '0' : 'var(--global-border-radius)',
						borderTopRightRadius: _vm.$vuetify.breakpoint.smAndUp ? '0' : 'var(--global-border-radius)',
					})},[_c('complex-background-base-styled-menu'),_vm._l((_vm.tempItems),function(tempItem,index){return _c('div',{key:tempItem.id,staticClass:"single-select-image-option",style:({
							marginBottom: index < _vm.tempItems.length - 1 ? '7px' : '0',
						}),on:{"click":function($event){return _vm.onSelectImageOption(tempItem, close)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"single-select-image-option-image"},[_c('img',{attrs:{"alt":tempItem.label,"src":_vm._get(_vm.localValue, 'image')}})]),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(tempItem.label)+" ")])]),_c('div',[_c(VIcon,{staticClass:"mr-3",staticStyle:{"color":"var(--primary-blue)","font-size":"23px"},style:({
									color: _vm._get(_vm.localValue, 'id') === tempItem.id ? 'var(--primary-blue)' : 'var(--grey-600)',
								})},[_vm._v(" "+_vm._s(_vm._get(_vm.localValue, 'id') === tempItem.id ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank')+" ")])],1)])})],2):_vm._e()]}}],null,false,136810491)})],1):(_vm._get(_vm.field, 'display.radios'))?_c('div',{staticClass:"runnit-radio-group"},[_c(VRadioGroup,{staticStyle:{"margin":"0"},attrs:{"value":_vm.localValue.id,"mandatory":_vm.field.required,"hide-details":"","rules":_vm.field.required ? _vm.rules.requiredRadioGroup : _vm.rules.optionalRadioGroup},on:{"input":function($event){return _vm.$emit('input', $event)}}},_vm._l((_vm.tempItems),function(item,index){return _c('div',{key:item.value,staticClass:"runnit-radio-item",style:({
					margin: index === _vm.tempItems.length - 1 ? '0' : '0 0 22px 0',
				})},[_c('div',{staticClass:"runnit-radio-label"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c(VRadio,{attrs:{"value":item.id,"label":""}})],1)}),0)],1):((_vm._get(_vm.field, 'display.options') || []).length < 8)?_c(VSelect,{class:{
			required: !!_vm.field.required,
		},attrs:{"items":_vm._get(_vm.field, 'display.options') || [],"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"item-text":"text","item-value":"value","dense":"","filled":"","flat":"","solo-inverted":"","hide-details":"auto","rules":_vm.field.required ? _vm.rules.requiredSelectField : _vm.rules.optionalSelectField,"value":_vm.value},on:{"input":_vm.emitInput}}):_c(VAutocomplete,{class:{
			required: !!_vm.field.required,
		},attrs:{"items":_vm._get(_vm.field, 'display.options') || [],"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"item-text":"text","item-value":"value","dense":"","filled":"","flat":"","solo-inverted":"","hide-details":"auto","rules":_vm.field.required ? _vm.rules.requiredSelectField : _vm.rules.optionalSelectField,"value":_vm.value},on:{"input":_vm.emitInput}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }