"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.determineRecurringBalanceAmountToAdd = exports.DEFAULT_TEAM_RECURRING_CAP = exports.DEFAULT_TEAM_RECURRING_ALLOTMENT = exports.DEFAULT_CREATORS_CLUB_RECURRING_CAP = exports.DEFAULT_CREATORS_CLUB_RECURRING_ALLOTMENT = exports.calcRealtimeBalanceInfo = void 0;
const lodash_1 = require("lodash");
const calcRealtimeBalanceInfo = (balanceAccount) => {
    if (!balanceAccount)
        return {
            realtimeBalance: 0,
            realtimeRecurringBalance: 0,
            realtimeRunnitTokens: 0,
        };
    const propKeys = (0, lodash_1.keys)(balanceAccount);
    return propKeys.reduce((result, key) => {
        const delta = balanceAccount[key] || 0;
        if (key.startsWith('balance_delta_')) {
            return {
                ...result,
                realtimeBalance: result.realtimeBalance + delta
            };
        }
        if (key.startsWith('recurringBalance_delta_')) {
            return {
                ...result,
                realtimeRecurringBalance: result.realtimeRecurringBalance + delta
            };
        }
        if (key.startsWith('runnitTokens_delta_')) {
            return {
                ...result,
                realtimeRunnitTokens: result.realtimeRunnitTokens + delta
            };
        }
        return result;
    }, {
        realtimeBalance: balanceAccount.balance || 0,
        realtimeRecurringBalance: balanceAccount.recurringBalance || 0,
        realtimeRunnitTokens: balanceAccount.runnitTokens || 0,
    });
};
exports.calcRealtimeBalanceInfo = calcRealtimeBalanceInfo;
exports.DEFAULT_CREATORS_CLUB_RECURRING_ALLOTMENT = 1000;
exports.DEFAULT_CREATORS_CLUB_RECURRING_CAP = 3000;
exports.DEFAULT_TEAM_RECURRING_ALLOTMENT = 2000;
exports.DEFAULT_TEAM_RECURRING_CAP = 6000;
const determineRecurringBalanceAmountToAdd = (balanceAccount) => {
    if (!balanceAccount)
        return 0;
    const { realtimeRecurringBalance } = (0, exports.calcRealtimeBalanceInfo)(balanceAccount);
    const defaultRecurringAllotment = balanceAccount.type === 'TEAM' ? exports.DEFAULT_TEAM_RECURRING_ALLOTMENT : exports.DEFAULT_CREATORS_CLUB_RECURRING_ALLOTMENT;
    const defaultRecurringCap = balanceAccount.type === 'TEAM' ? exports.DEFAULT_TEAM_RECURRING_CAP : exports.DEFAULT_CREATORS_CLUB_RECURRING_CAP;
    const recurringAllotment = (0, lodash_1.has)(balanceAccount, 'recurringAllotment')
        ? (balanceAccount.recurringAllotment || 0)
        : defaultRecurringAllotment;
    const recurringCap = (0, lodash_1.has)(balanceAccount, 'recurringCap')
        ? (balanceAccount.recurringCap || 0)
        : defaultRecurringCap;
    return (0, lodash_1.clamp)(recurringCap - realtimeRecurringBalance, 0, recurringAllotment);
};
exports.determineRecurringBalanceAmountToAdd = determineRecurringBalanceAmountToAdd;
