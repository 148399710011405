import { render, staticRenderFns } from "./PurpleChip.vue?vue&type=template&id=27724898&scoped=true&"
import script from "./PurpleChip.vue?vue&type=script&lang=ts&"
export * from "./PurpleChip.vue?vue&type=script&lang=ts&"
import style0 from "./PurpleChip.vue?vue&type=style&index=0&id=27724898&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27724898",
  null
  
)

export default component.exports