
import Vue from 'vue';
import _sampleSize from 'lodash/sampleSize';
import _remove from 'lodash/remove';
import { localPrompts } from '@/views/Runnits/utils/localData';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';
import PromptGenerator from '@/views/Runnits/RunnitSettings/PromptGenerator/PromptGenerator.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
	name: 'Prompt',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: String, default: null },
	},
	data () {
		return {
			loadingPrompt: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'incrementFireInitialPromptGenerationTrigger',
		]),
		handleGenerateRandomPrompt () {
			this.loadingPrompt = true;

			const randomPromptsArray: string[] = [...localPrompts];
			if (this.value) _remove(randomPromptsArray, (pr: string) => pr === this.value); // Remove last prompt
			const randomPrompt: string = _sampleSize(randomPromptsArray, 1)[0];

			setTimeout(() => {
				this.$emit('input', randomPrompt);
				this.loadingPrompt = false;
			}, 150);
		},
		onUseEnhancedPrompt (positive: string, negative: string) {
			if (negative) {
				if (negative !== this.runnitState.generatedNegativePrompt) {
					this.updateRunnitState({ generatedNegativePrompt: negative });
				} else {
					// If it hasn't changed, tell the negative prompt field that it should re-init the mode (default, custom, none)
					this.updateRunnitState({ generatedNegativePrompt: null });
					this.$nextTick(() => {
						this.updateRunnitState({ generatedNegativePrompt: negative });
					});
				}
			}
			if (positive) this.$emit('input', positive);
		},
		clearPrompt () {
			this.$emit('input', '');
		},
		openPromptGenerator () {
			this.updateRunnitState({
				promptGeneratorOpen: true,
				// Close all other potentially open runnit menus
				inpaintingEditorOpen: false,
			});
			this.incrementFireInitialPromptGenerationTrigger();
		},
	},
	components: {
		PromptGenerator,
		RunnitSettingHelperBtn,
		RunnitDynamicFieldLabelRow,
	},
});
