
import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
	name: 'Runnits',
	props: {},
	data () {
		return {};
	},
	created () {
		this.updateToolbar({
			insideRunnits: true,
		});
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
	},
});
