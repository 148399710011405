
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { GTAG_EVENTS, SNACKBAR_STATUS } from '@/constants/constants';
import { db, functions } from '@/firebase';
import { loadStripe } from '@stripe/stripe-js';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import {
	Team,
	User,
	STRIPE_PUBLISHABLE_KEY,
	CHECKOUT_ADD_FUNDS,
	CHECKOUT_ADD_FUNDS_TEAM,
	CHECKOUT_CREATORS_CLUB_MEMBERSHIP,
	CHECKOUT_TEAM_PLAN_SUBSCRIPTION,
	STRIPE_TEAMS_PUBLISHABLE_KEY,
} from '@run-diffusion/shared';

export default Vue.extend({
	name: 'GoToPayment',
	data () {
		return {
			loadingCheckOutPage: true,

			loadingPageText: '',
			successPageHeader: '',
			successPageText: '',
			hasInitPage: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
		]),
	},
	async created () {
		await this.initGoToPaymentPage(this.user, this.team);
		setTimeout(() => {
			this.loadingPageText = 'This is taking a while... Please try again by refreshing or closing this window. You can reach out to report issues by clicking the support button in our top toolbar.';
		}, 15000);
	},
	watch: {
		async user (newVal: User) {
			await this.initGoToPaymentPage(newVal, this.team);
		},
		async team (newVal: Team) {
			await this.initGoToPaymentPage(this.user, newVal);
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async initGoToPaymentPage (user: User, team: Team) {
			const hasReturnActionQueryVariable: boolean = !!this._get(this.$route.query, 'returnAction', null);
			const isSuccess: boolean = this._get(this.$route.query, 'returnAction', null) === 'success';
			const isCancel: boolean = this._get(this.$route.query, 'returnAction', null) === 'cancel';
			const isAddFundsCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_ADD_FUNDS;
			const isTeamAddFundsCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_ADD_FUNDS_TEAM;
			const isCcMembershipCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_CREATORS_CLUB_MEMBERSHIP;
			const isTeamSubscriptionCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_TEAM_PLAN_SUBSCRIPTION;

			if (
				this.hasInitPage ||
				(isAddFundsCheckout && !user) ||
				(isTeamAddFundsCheckout && (!team || !user)) ||
				(isCcMembershipCheckout && !user) ||
				(isTeamSubscriptionCheckout && (!team || !user))
			) {
				return;
			}

			this.hasInitPage = true;

			if (window.fpr && isCcMembershipCheckout) {
				window.fpr('referral', {
					email: this.user.email,
				});
			}

			if (hasReturnActionQueryVariable) {
				if (isSuccess) {
					this.loadingCheckOutPage = false;
					if (isAddFundsCheckout || isTeamAddFundsCheckout) {
						this.successPageHeader = 'Funds have been added successfully!';
						this.successPageText = 'You can now close this tab and your funds will be available.';
					} else if (isCcMembershipCheckout) {
						this.successPageHeader = `Creator's Club purchased successfully!`;
						this.successPageText = `You can now close this tab. Your membership and benefits will be active. Please check your email inbox for Creater's Club documentation that was just sent to you.`;
					} else if (isTeamSubscriptionCheckout) {
						this.successPageHeader = `Team Plan purchased successfully!`;
						this.successPageText = `You can now close this tab. Your subscription and benefits will be active. Please check your email inbox for next steps.`;
					}
				} else if (isCancel) {
					this.handleWindowClose();
				}
			} else if (this.user) {
				if (isAddFundsCheckout || isTeamAddFundsCheckout) {
					this.loadingPageText = 'payment';
					await this.goToAddFundsCheckOutPage(isTeamAddFundsCheckout ? team : null);
				} else if (isCcMembershipCheckout || isTeamSubscriptionCheckout) {
					this.loadingPageText = 'subscription';
					await this.goToBuySubscriptionPage(isTeamSubscriptionCheckout ? team : null);
				}
			}
		},
		handleWindowClose () {
			console.log('handleWindowClose');
			window.close();
		},
		async goToAddFundsCheckOutPage (teamForCheckout: Team) {
			const priceId: string = this._get(this.$route.query, 'priceId', null);
			const onError: Function = (e: any) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading payment page, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};

			try {
				this.$gtag.event(GTAG_EVENTS.ON_ADD_FUNDS_CLICK, {
					event_label: 'On Add Funds Click',
					screen_name: this.user.email,
				});

				if (teamForCheckout) {
					const publishableKey: string = STRIPE_TEAMS_PUBLISHABLE_KEY;

					const checkoutDocRef: any = db
						.collection(`stripeTeamsCustomers/${teamForCheckout.id}/checkout_sessions`)
						.doc();

					const functionRef = functions
						.httpsCallable('newAddFundsTeamsCheckout');
					const { error, checkoutSessionId } = (await functionRef({
						teamId: teamForCheckout.id,
						checkoutDocId: checkoutDocRef.id,
						priceId,
					})).data;

					await this.processCheckoutData(error, checkoutSessionId, publishableKey, onError);
				} else {
					const publishableKey: string = STRIPE_PUBLISHABLE_KEY;

					const checkoutDocRef: any = db
						.collection(`stripeCustomers/${this.user.id}/checkout_sessions`)
						.doc();

					// Wait for the checkoutSession to get attached by the extension
					checkoutDocRef.onSnapshot(async (checkoutSnapshot) => {
						const { error, sessionId: checkoutSessionId } = checkoutSnapshot.data();
						await this.processCheckoutData(error, checkoutSessionId, publishableKey, onError);
					});

					const functionRef = functions
						.httpsCallable('newAddFundsCheckout');
					const { success } = (await functionRef({
						checkoutDocId: checkoutDocRef.id,
						priceId,
					})).data;
					if (!success) onError(new Error('newAddFundsCheckout returned not success'));
				}
			} catch (e) {
				onError(e);
			}
		},
		async goToBuySubscriptionPage (teamForCheckout: Team) {
			const isAnnual: boolean = !!this._get(this.$route.query, 'annual', null);

			const onError: Function = (e: any) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading subscription page, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};

			try {
				if (teamForCheckout) {
					const publishableKey: string = STRIPE_TEAMS_PUBLISHABLE_KEY;

					const checkoutDocRef: any = db
						.collection(`stripeTeamsCustomers/${teamForCheckout.id}/checkout_sessions`)
						.doc();

					const functionRef = functions
						.httpsCallable('newSubscriptionTeamsCheckout');
					const { error, checkoutSessionId } = (await functionRef({
						teamId: teamForCheckout.id,
						checkoutDocId: checkoutDocRef.id,
						isAnnual,
					})).data;

					await this.processCheckoutData(error, checkoutSessionId, publishableKey, onError);
				} else {
					const publishableKey: string = STRIPE_PUBLISHABLE_KEY;

					const checkoutDocRef: any = db
						.collection(`stripeCustomers/${this.user.id}/checkout_sessions`)
						.doc();

					// Wait for the checkoutSession to get attached by the extension
					checkoutDocRef.onSnapshot(async (checkoutSnapshot) => {
						const { error, sessionId: checkoutSessionId } = checkoutSnapshot.data();
						await this.processCheckoutData(error, checkoutSessionId, publishableKey, onError);
					});

					const functionRef = functions
						.httpsCallable('newSubscriptionCheckout');
					const { success } = (await functionRef({
						checkoutDocId: checkoutDocRef.id,
						isAnnual,
					})).data;
					if (!success) onError(new Error('newSubscriptionCheckout returned not success'));
				}
			} catch (e) {
				onError(e);
			}
		},
		async processCheckoutData (error: string, checkoutSessionId: string, publishableKey: string, onError: Function) {
			try {
				if (error) {
					onError(error);
				}

				if (checkoutSessionId) {
					// We have a session, let's redirect to Checkout
					// Init Stripe
					const stripe = await loadStripe(publishableKey);
					await stripe.redirectToCheckout({ sessionId: checkoutSessionId });
				}
			} catch (e) {
				onError(e);
			}
		},
	},
	components: {
		LoadingSVG,
	},
});
