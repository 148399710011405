import Vue from 'vue';
import {
	currency,
	centsToDollars,
	currencyTokens,
	thousandthsToTokens,
} from '@run-diffusion/shared';
import pluralize from 'pluralize';
import _join from 'lodash/join';
import _truncate from 'lodash/truncate';
import prettyBytes from 'pretty-bytes';

/*
Filter methods
 */
export const truncateMiddle: (str: string, length: number) => string = (str: string, length: number): string => {
	if (!str || str.length <= length) return str;

	const ellipsis: string = '...';
	const charsToShow: number = length - ellipsis.length;
	const frontChars: number = Math.ceil(charsToShow / 2);
	const backChars: number = Math.floor(charsToShow / 2);

	return _join([
		_truncate(str.slice(0, frontChars), { length: frontChars, omission: '' }),
		ellipsis,
		_truncate(str.slice(str.length - backChars), { length: backChars, omission: '' })
	], '');
}

export const twoDecimals: (value: number) => number = (value: number): number => {
	if (typeof value !== 'number') return value;
	return Math.round(value * 100) / 100;
};

/*
Add filters to Vue
 */
Vue.filter('currency', currency);
Vue.filter('centsToDollars', centsToDollars);
Vue.filter('currencyTokens', currencyTokens);
Vue.filter('thousandthsToTokens', thousandthsToTokens);
Vue.filter('truncate', _truncate);
Vue.filter('truncateMiddle', truncateMiddle);
Vue.filter('prettyBytes', prettyBytes);
Vue.filter('pluralize', pluralize);
Vue.filter('twoDecimals', twoDecimals);
