import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnits-home",style:({
		padding: _vm.$vuetify.breakpoint.xsOnly ? '0 15px' : '0 25px',
	})},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('runnits-promo-card',{staticStyle:{"margin-top":"25px","margin-bottom":"15px"}})],1)],1),_c(VRow,{attrs:{"align-content":"center"}},[_c(VCol,{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('runnit-tools-gallery'),_c('div',{staticClass:"runnits-section"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center",style:({
								flexWrap: _vm.$vuetify.breakpoint.xsOnly ? 'wrap' : 'nowrap',
							})},[_c('div',{staticClass:"runnits-section-title mt-1"},[_vm._v(" "+_vm._s(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM ? 'Team Runnits' : 'Private Runnits')+" ")]),(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM)?_c(VBtnToggle,{staticClass:"mr-2",class:{
									'ml-6': _vm.$vuetify.breakpoint.smAndUp,
									'mt-2 mb-5': _vm.$vuetify.breakpoint.xsOnly,
								},attrs:{"value":_vm.runnitState.runnitsAccessLevel,"mandatory":"","rounded":"","dense":""},on:{"change":_vm.onRunnitsAccessLevelChange}},[_c(VBtn,{attrs:{"value":_vm.RUNNITS_ACCESS_LEVEL.PRIVATE,"large":""}},[_c('div',{staticClass:"d-flex align-center pl-1"},[_c(VIcon,{staticClass:"pl-1 pr-2 gradient-text",staticStyle:{"font-size":"22px"}},[_vm._v("mdi-account-circle-outline")]),_c('div',{staticClass:"d-flex flex-column align-start",staticStyle:{"padding":"0 5px 0 0"}},[_c('div',[_vm._v("Private")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticStyle:{"margin":"2px 0 0 0","font-size":"10px","color":"var(--grey-400)"}},[_vm._v(" Only you can view ")]):_vm._e()])],1)]),_c(VBtn,{attrs:{"value":_vm.RUNNITS_ACCESS_LEVEL.SHARED,"large":""}},[_c('div',{staticClass:"d-flex align-center pr-1"},[_c(VIcon,{staticClass:"pl-1 pr-2 gradient-text",staticStyle:{"font-size":"22px"}},[_vm._v("mdi-account-multiple-outline")]),_c('div',{staticClass:"d-flex flex-column align-start",staticStyle:{"padding":"0 5px 0 0"}},[_c('div',[_vm._v("Shared")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticStyle:{"margin":"2px 0 0 0","font-size":"10px","color":"var(--grey-400)"}},[_vm._v(" Every team member can view ")]):_vm._e()])],1)])],1):_vm._e()],1)]),_c('div',{staticClass:"runnits-section-subTitle"},[_vm._v(" Runnits are basically a "),_c('span',{staticClass:"gradient-text"},[_vm._v("board")]),_vm._v(" with "),_c('span',{staticClass:"gradient-text"},[_vm._v("one or more tools")]),_vm._v(". Create your own Runnit and start adding tools! ")]),((
							_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM &&
							!_vm.team
						))?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('empty-state',{attrs:{"row-class":"mt-12 pt-8 pb-6","icon":"mdi-account-multiple-outline","message":"No team selected!"}}),_c('team-picker',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({staticClass:"mt-6",attrs:{"secondary-primary":""}},'base-button',attrs,false),on),[_vm._v(" Click here to select team ")])]}}],null,false,1189684346)})],1):(_vm.loadingRunnits === null || _vm.loadingRunnits)?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c(VProgressCircular,{staticClass:"my-10",attrs:{"indeterminate":true,"size":"50"}}),_c('div',[_vm._v("Loading...")])],1):_c('div',{staticClass:"runnit-nodes-container"},[_c('div',{class:{
								'full-width': _vm.$vuetify.breakpoint.xsOnly,
							}},[_c(VTooltip,{attrs:{"top":"","open-delay":"500","nudge-top":-80},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"add-another-runnit",class:{
											'mb-7': !!_vm.runnits.length,
											'mr-7': _vm.$vuetify.breakpoint.smAndUp && !!_vm.runnits.length,
										},style:({
											width: _vm.$vuetify.breakpoint.xsOnly ? '100%' : '300px',
										}),on:{"click":_vm.onAddRunnitClick}},on),[_c(VIcon,{staticClass:"add-another-runnit-icon",staticStyle:{"font-size":"55px"}},[_vm._v("mdi mdi-plus-circle-multiple")])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" Add a new Runnit ")])])],1),_vm._l((_vm.runnits),function(runnit){return [(!runnit.avatar || typeof runnit.avatar !== 'string')?_c('offering-card',{key:runnit.id,staticClass:"mb-7",class:{
									'mr-7': _vm.$vuetify.breakpoint.smAndUp && !!_vm.runnits.length,
								},attrs:{"title":runnit.title,"avatar":runnit.avatar,"description":runnit.description,"tool-type":_vm.RUNNIT_NODE_DEF_TOOL_TYPE.USER_CREATED},on:{"click":function($event){return _vm.onOfferingCardClick(runnit.id, null)}}}):_vm._e()]})],2)])],1)],1)],1),_c('runnits-info-dialog',{attrs:{"title":"Runnits Limit Reached","body-height":"60vh","actions-styles":"margin: 5px 0 20px 0;","banner-src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Frd-webapp%2Frunnits-max-dialog-banner.png?alt=media&token=6dc81b26-a38f-4e92-8443-9be2d2b0072d"},model:{value:(_vm.showRunnitsMaxDialog),callback:function ($$v) {_vm.showRunnitsMaxDialog=$$v},expression:"showRunnitsMaxDialog"}},[_c('div',{staticClass:"full-width",staticStyle:{"margin":"-20px 0 0 0","font-size":"17px","max-width":"525px","color":"var(--grey-500)","text-align":"center"}},[_vm._v(" You can only have "),_c('div',{staticClass:"gradient-text",staticStyle:{"display":"inline"}},[_c('b',[_vm._v(_vm._s(_vm.MAX_NUM_FREE_RUNNITS))]),_vm._v(" free Runnits")]),_vm._v(" at a time. Sign up for a "),_c('b',[_vm._v("paid plan")]),_vm._v(" or add more tools to one of your "),_c('b',[_vm._v("existing Runnits")]),_vm._v(" below! ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }