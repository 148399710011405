
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapState } from 'vuex';
import { RUNNITS_ACCESS_LEVEL, RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import { RunnitsAccessLevel } from '../../../../../shared/src';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { setRunnitsTeamAccessLevel } from '@/utils';
import { RunnitsCRUDMixin, RunnitsAndRunnitNodeDefsBindingsMixin } from '@/mixins';
import TeamPicker from '@/components/TeamPicker.vue';
import OfferingCard from '@/components/OfferingCard.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import RunnitsPromoCard from '@/views/Runnits/RunnitsPromoCard.vue';
import RunnitsInfoDialog from '@/views/Runnits/RunnitsInfoDialog.vue';
import RunnitToolsGallery from '@/views/Runnits/RunnitToolsGallery.vue';

export default Vue.extend({
	name: 'RunnitsHome',
	mixins: [
		RunnitsCRUDMixin,
		RunnitsAndRunnitNodeDefsBindingsMixin,
	],
	data () {
		return {
			RUNNITS_OWNER_SELECTION,
			RUNNITS_ACCESS_LEVEL,
			RUNNIT_NODE_DEF_TOOL_TYPE,
			addingNewRunnit: false,

			MAX_NUM_FREE_RUNNITS: 5,
			showRunnitsMaxDialog: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnits',
			'runnitsMap',
			'runnitState',
			'loadingRunnits',
		]),
	},
	methods: {
		...mapActions([
			'updateLoader',
			'updateSnackbar',
			'updateRunnitState',
		]),
		_isEmpty,
		onRunnitsAccessLevelChange (runnitsAccessLevel: RunnitsAccessLevel) {
			setRunnitsTeamAccessLevel(runnitsAccessLevel);
			this.updateRunnitState({
				runnitsAccessLevel,
			});
		},
		goHome () {
			this.routerPush(this.$route, this.$router, { name: 'Sessions' });
		},
	},
	components: {
		RunnitToolsGallery,
		RunnitsPromoCard,
		EmptyState,
		TeamPicker,
		RunnitsInfoDialog,
		OfferingCard,
	},
});
