
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { EraserBrush } from '@erase2d/fabric';
import { Canvas, Image as FabricImage, PencilBrush, Object as FabricObject } from 'fabric';
import { getRunnitsInpaintingIsExpanded, setRunnitsInpaintingIsExpanded } from '@/utils';
import BaseSlider from '@/components/base/BaseSlider.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import RunnitMenuOrBottomSheet from '@/views/Runnits/base/RunnitMenuOrBottomSheet.vue';
import ComplexBackgroundSettingMenu2 from '@/components/designElements/ComplexBackgroundSettingMenus2.vue';

export default Vue.extend({
	name: 'InpaintingEditor',
	props: {
		value: { type: Boolean, default: false },
	},
	data () {
		return {
			SELECTION: {
				BRUSH: 'BRUSH',
				ERASER: 'ERASER',
			},
			STATIC_HEIGHT: 462,
			STATIC_WIDTH: 462,
			brushSize: 30,
			selection: 'BRUSH',
			canvas: null as Canvas | null,
			history: [] as FabricObject[],
			backgroundImage: null,
			eraserBrush: null as EraserBrush | null,
			pencilBrush: null as PencilBrush | null,

			isExpandedMode: false,
		};
	},
	created () {
		this.isExpandedMode = getRunnitsInpaintingIsExpanded();
	},
	computed: {
		...mapState(['runnitState']),
		CANVAS_HEIGHT (): number {
			return this.STATIC_HEIGHT;
		},
		CANVAS_WIDTH (): number {
			// return this.CANVAS_HEIGHT * (512 / this.STATIC_HEIGHT);
			return this.STATIC_WIDTH;
		},
	},
	watch: {
		'$store.state.runnitState.inpaintingEditorOpen': {
			immediate: true,
			async handler(newVal: boolean) {
				if (!this.canvas && newVal) {
					this.$nextTick(() => {
						this.initCanvas();
					});
				}
			},
		},
		isExpandedMode: {
			immediate: true,
			async handler(newVal: boolean) {
				this.$nextTick(() => {
					this.initCanvas();
				});
			},
		},
		brushSize: {
			handler(newSize: number) {
				if (this.canvas) {
					this.canvas.freeDrawingBrush.width = newSize;
				}
			},
		},
	},
	methods: {
		...mapActions(['updateRunnitState']),
		handleExpandedClick () {
			this.isExpandedMode = !this.isExpandedMode;
			setRunnitsInpaintingIsExpanded(this.isExpandedMode);
		},
		initCanvas () {
			console.log('initCanvas');
			this.canvas = new Canvas('brush-canvas', {
				width: this.CANVAS_WIDTH,
				height: this.CANVAS_HEIGHT,
				isDrawingMode: true,
			});
			const localImageUrl: string = require('@/assets/local_development_hedgehog.jpg');

			FabricImage.fromURL(
				localImageUrl,
				{
					crossOrigin: 'anonymous',
				}
			).then((img) => {
				if (this.canvas) {
					img.scaleToWidth(this.CANVAS_WIDTH);
					this.canvas.add(img);
					this.canvas.renderAll();
					this.backgroundImage = img;
				}
			});

			this.setupBrushes();

			this.canvas.on('object:added', this.onObjectAdded);
			window.addEventListener('resize', this.handleResize);
		},
		setupBrushes () {
			if (this.canvas) {
				this.pencilBrush = new PencilBrush(this.canvas);
				this.pencilBrush.width = this.brushSize;
				this.pencilBrush.color = 'rgba(255, 255, 255, 1)';

				this.eraserBrush = new EraserBrush(this.canvas);
				this.eraserBrush.width = this.brushSize;

				this.canvas.freeDrawingBrush = this.pencilBrush;
			}
		},
		onClose () {
			this.updateRunnitState({
				inpaintingEditorOpen: false,
			});
		},
		handleDone () {
			if (this.canvas) {
				const dataURL = this.canvas.toDataURL({ format: 'png' });
				console.log('Canvas data URL:', dataURL);
				// Handle the dataURL as needed
			}
		},
		onSelection (selection: string) {
			this.selection = selection;
			if (this.canvas) {
				if (selection === this.SELECTION.ERASER) {
					this.canvas.freeDrawingBrush = this.eraserBrush;
				} else {
					this.canvas.freeDrawingBrush = this.pencilBrush;
				}
			}
		},
		isSelected (selection: string) {
			return this.selection === selection;
		},
		onStepBack () {
			if (this.history.length > 0 && this.canvas) {
				const lastAction = this.history.pop();
				if (lastAction) {
					this.canvas.remove(lastAction);
					this.canvas.renderAll();
				}
			}
		},
		onDeleteInpaintingMask () {
			if (this.canvas) {
				this.canvas.getObjects().forEach((obj) => {
					if (obj !== this.backgroundImage) {
						this.canvas.remove(obj);
					}
				});
				this.history = [];
				this.canvas.renderAll();
			}
		},
		handleResize () {
			if (this.canvas) {
				this.canvas.setDimensions({
					width: this.CANVAS_WIDTH,
					height: this.CANVAS_HEIGHT,
				});
				this.canvas.renderAll();
			}
		},
		onObjectAdded (e: { target: FabricObject }) {
			console.log('onObjectAdded');
			if (e.target !== this.backgroundImage) {
				this.history.push(e.target);
			}
		},
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.handleResize);
		if (this.canvas) {
			this.canvas.off('object:added', this.onObjectAdded);
			this.canvas.dispose();
		}
	},
	components: {
		BaseSlider,
		GlassButton,
		RunnitMenuOrBottomSheet,
		ComplexBackgroundSettingMenu2,
	},
});
