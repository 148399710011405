
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import RunnitSelectedImage from '@/views/Runnits/RunnitSelectedImage.vue';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';

export default Vue.extend({
	name: 'ImageInputSelectedImageContainer',
	props: {
		value: { type: Object, default: null },
		libraryText: { type: String, default: 'Library' },
		hasInpainting: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'user',
			'runnitState',
		]),
		isImageSelected () {
			return !!(
				this.value &&
				(
					this.value.nodeRunResult ||
					this.value.upload
				)
			);
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		onClear () {
			this.$emit('on-clear');
		},
		onLibrary () {
			this.$emit('on-library');
		},
		handleInpaintingClick () {
			this.updateRunnitState({
				// Close all other potentially open runnit menus
				promptGeneratorOpen: false,
				inpaintingEditorOpen: !this.runnitState.inpaintingEditorOpen,
			});
		},
	},
	components: {
		RunnitSelectedImage,
		RunnitSettingHelperBtn,
	},
});
