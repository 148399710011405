import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"select-container"},[_c('div',{staticClass:"select-container-option all-type-background",on:{"click":function($event){return _vm.onSelectAll(_vm.allowTeamCategorySelection)}}},[_c('div',{staticClass:"select-container-option-selection"},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"}},[_vm._v(" "+_vm._s(_vm.isSelectAllFilledIn ? 'mdi-circle-slice-8' : 'mdi-circle-outline')+" ")])],1),_c('div',{staticClass:"select-container-option-label"},[_vm._v(" All ")])]),_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"select-container-option",class:{
			'team-type-background': item.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.TEAM,
			'core-type-background': item.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
			'curated-type-background': item.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
		},on:{"click":function($event){return _vm.handleOptionSelection(item)}}},[_c('div',{staticClass:"select-container-option-selection"},[_c(VIcon,{style:({
					...(item.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.TEAM && {
						color: 'var(--blue-grey-400)'
					}),
					...(item.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.CORE && {
						color: 'var(--primary-blue-opacity-60)'
					}),
					...(item.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.CURATED && {
						color: 'var(--primary-purple)'
					}),
				})},[_vm._v(" "+_vm._s(!_vm.isSelectAllFilledIn && _vm.selectedTypesMap[item.toolType] ? 'mdi-square-rounded' : 'mdi-square-rounded-outline')+" ")])],1),_c('div',{staticClass:"select-container-option-label no-select"},[_vm._v(" "+_vm._s(item.label)+" ")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }