
import Vue from 'vue';
import { mapState } from 'vuex';
import { MyBalanceMixin } from '@/mixins';
import { RUNNITS_OWNER_SELECTION } from './constants';
import TokensSVG from '@/assets/TokensSVG.vue';

export default Vue.extend({
	name: 'RunnitTokens',
	mixins: [
		MyBalanceMixin,
	],
	props: {
		background: { type: String, default: 'rgba(0, 0, 0, 0.6)' },
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		runnitTokens () {
			if (!this.runnitState) return null;

			if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER) {
				return this.userRunnitTokens;
			}

			if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM) {
				if (!this.team) return null;
				return this.teamRunnitTokens;
			}

			return null;
		},
	},
	components: {
		TokensSVG,
	},
});
