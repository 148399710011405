
import Vue from 'vue';
import _isNil from 'lodash/isNil';
import NumberWithIncrementer from '@/views/Runnits/RunnitSettings/NumberWithIncrementer.vue';

export default Vue.extend({
	name: 'BaseSlider',
	methods: {
		_isNil,
		onIncrement (val: number) {
			this.$emit('input', val);
			this.$emit('change', val);
		},
	},
	components: {
		NumberWithIncrementer,
	},
});
