
import Vue from 'vue';
import _trim from 'lodash/trim';
import { mapState } from 'vuex';
import { RunnitNodeRun } from '@run-diffusion/shared';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import { RunnitState } from '@/store';

type NegativePromptMode =
	'DEFAULT' |
	'CUSTOM' |
	'NONE'
;
export default Vue.extend({
	name: 'NegativePrompt',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: String, default: null },
	},
	data () {
		const NEGATIVE_PROMPT_MODE: Record<NegativePromptMode, NegativePromptMode> = {
			DEFAULT: 'DEFAULT',
			CUSTOM: 'CUSTOM',
			NONE: 'NONE',
		};

		return {
			NEGATIVE_PROMPT_MODE,
			mode: NEGATIVE_PROMPT_MODE.NONE,
			lastCustomPrompt: null,
			needInitMode: true,
		};
	},
	computed: {
		...mapState([
			'draftRunnitNodeRun',
			'runnitState',
		]),
	},
	watch: {
		draftRunnitNodeRun: {
			immediate: true,
			handler (newVal: RunnitNodeRun, oldVal: RunnitNodeRun) {
				if (!newVal) {
					this.needInitMode = true;
				}
				if (newVal !== oldVal) {
					this.initMode(newVal, this.value);
				}
			},
		},
		value: {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.initMode(this.draftRunnitNodeRun, newVal);
				}
			},
		},
		runnitState: {
			immediate: true,
			handler (newVal: RunnitState, oldVal: RunnitState) {
				if (
					newVal !== oldVal &&
					newVal.generatedNegativePrompt &&
					newVal.generatedNegativePrompt !== this.value &&
					newVal.generatedNegativePrompt !== (this._get(oldVal, 'generatedNegativePrompt') || null)
				) {
					this.needInitMode = true;
					this.onInput(newVal.generatedNegativePrompt);
				}
			},
		},
	},
	methods: {
		initMode (draftRunnitNodeRun: RunnitNodeRun, value: string) {
			if (!this.needInitMode) return;
			this.needInitMode = false;

			const trimmedValue: string = _trim(value);
			const trimmedFieldDefaultValue: string = _trim(this.field.defaultValue);
			if (
				trimmedFieldDefaultValue &&
				(
					!draftRunnitNodeRun ||
					trimmedFieldDefaultValue === trimmedValue
				)
			) {
				this.mode = this.NEGATIVE_PROMPT_MODE.DEFAULT;
			} else if (trimmedValue) {
				this.mode = this.NEGATIVE_PROMPT_MODE.CUSTOM;
			} else {
				this.mode = this.NEGATIVE_PROMPT_MODE.NONE;
			}
		},
		onSelectMode (mode: NegativePromptMode) {
			const trimmedDefaultValue: string = _trim(this.field.defaultValue);
			const trimmedValue: string = _trim(this.value);
			const trimmedLastCustomPrompt: string = _trim(this.lastCustomPrompt);

			const oldMode: NegativePromptMode = this.mode;
			if (oldMode === mode) return;
			if (mode !== this.NEGATIVE_PROMPT_MODE.CUSTOM && oldMode === this.NEGATIVE_PROMPT_MODE.CUSTOM) {
				this.lastCustomPrompt = trimmedValue || null;
			}

			switch (mode) {
				case this.NEGATIVE_PROMPT_MODE.DEFAULT:
					this.mode = this.NEGATIVE_PROMPT_MODE.DEFAULT;
					this.onInput(trimmedDefaultValue || null);
					break;
				case this.NEGATIVE_PROMPT_MODE.CUSTOM:
					this.mode = this.NEGATIVE_PROMPT_MODE.CUSTOM;
					this.onInput(trimmedLastCustomPrompt || trimmedValue || trimmedDefaultValue || null);
					break;
				case this.NEGATIVE_PROMPT_MODE.NONE:
					this.mode = this.NEGATIVE_PROMPT_MODE.NONE;
					this.onInput(null);
					break;
				default:
					break;
			}
		},
		onInput (value: string) {
			this.$emit('input', value);
		},
	},
	components: {
		RunnitSettingHelperBtn,
		RunnitDynamicFieldLabelRow,
	},
});
