
import Vue from 'vue';

export default Vue.extend({
	name: 'BaseButton',
	props: {
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		elevation: { type: Number, default: 0 },
		secondary: { type: Boolean, default: false },
		secondaryPrimary: { type: Boolean, default: false },
		superPrimary: { type: Boolean, default: false },
		tertiary: { type: Boolean, default: false },
		warning: { type: Boolean, default: false },
		warningConfirm: { type: Boolean, default: false },
		text: { type: Boolean, default: false },
		link: { type: Boolean, default: false },
		rounded: { type: Boolean, default: true },
		color: { type: String, default: null },
		callToAction: { type: Boolean, default: false },
		cssClass: { type: [String, Object], default: 'px-4 font-weight-bold' },
	},
	computed: {
		isPrimary () {
			return (
				!this.disabled &&
				!this.superPrimary &&
				!this.secondary &&
				!this.secondaryPrimary &&
				!this.callToAction &&
				!this.text &&
				!this.warning &&
				!this.warningConfirm &&
				!this.tertiary &&
				!this.link
			);
		},
		isSuperPrimary () {
			return (
				this.superPrimary &&
				!this.disabled &&
				!this.secondary &&
				!this.secondaryPrimary &&
				!this.primary &&
				!this.callToAction &&
				!this.text &&
				!this.warning &&
				!this.warningConfirm &&
				!this.link
			);
		},
		isSecondary () {
			return (
				this.secondary &&
				!this.callToAction &&
				!this.secondaryPrimary &&
				!this.text &&
				!this.link
			);
		},
		isSecondaryPrimary () {
			return (
				this.secondaryPrimary &&
				!this.primary &&
				!this.superPrimary &&
				!this.secondary &&
				!this.callToAction &&
				!this.text &&
				!this.link &&
				!this.disabled
			);
		},
		isTertiary () {
			return (
				this.tertiary &&
				!this.secondary &&
				!this.secondaryPrimary &&
				!this.callToAction &&
				!this.text
			);
		},
		isLink () {
			return (
				this.link &&
				!this.callToAction
			);
		},
		btnColor () {
			let color = '';

			if (this.secondary) {
				color = '#b0bec5';
			} else if (this.callToAction) {
				color = '#6f86ff';
			} else if (this.warning || this.warningConfirm) {
				color = '#dc2626';
			} else if (this.isTertiary) {
				color = '#424242FF';
			}

			// Override if given color prop
			if (this.color) {
				color = this.color;
			}

			return color;
		},
	},
});
