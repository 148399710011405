import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field,"label-styles":{
			...(_vm.mode === _vm.NEGATIVE_PROMPT_MODE.NONE && {
				padding: '0',
			}),
		}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.field.defaultValue)?_c('runnit-setting-helper-btn',{staticClass:"mr-2 ml-2",attrs:{"min-width":"70px","selected":_vm.mode === _vm.NEGATIVE_PROMPT_MODE.DEFAULT},on:{"click":function($event){return _vm.onSelectMode(_vm.NEGATIVE_PROMPT_MODE.DEFAULT)}}},[_vm._v(" Default ")]):_vm._e(),_c('runnit-setting-helper-btn',{staticClass:"mr-2",attrs:{"min-width":"70px","selected":_vm.mode === _vm.NEGATIVE_PROMPT_MODE.CUSTOM},on:{"click":function($event){return _vm.onSelectMode(_vm.NEGATIVE_PROMPT_MODE.CUSTOM)}}},[_vm._v(" Custom ")]),_c('runnit-setting-helper-btn',{attrs:{"min-width":"70px","selected":_vm.mode === _vm.NEGATIVE_PROMPT_MODE.NONE},on:{"click":function($event){return _vm.onSelectMode(_vm.NEGATIVE_PROMPT_MODE.NONE)}}},[_vm._v(" None ")])]},proxy:true}])}),_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade-absolute","tag":"div"}},[(_vm.mode === _vm.NEGATIVE_PROMPT_MODE.DEFAULT)?_c('div',{key:"option-default"},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"default-prompt"},on),[_c('b',[_vm._v("Default:")]),_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.field.defaultValue,{ length: 65 }))+" ")])]}}],null,false,408756914)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" "+_vm._s(_vm.field.defaultValue)+" ")])])],1):_vm._e(),(_vm.mode === _vm.NEGATIVE_PROMPT_MODE.CUSTOM)?_c(VTextarea,{key:"option-custom",staticClass:"full-width",class:{
				required: !!_vm.field.required,
			},attrs:{"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"dense":"","filled":"","flat":"","solo-inverted":"","rows":"4","hide-details":"auto","counter":_vm.value && _vm.value.length >= _vm.SHOW_TEXT_FIELD_COUNTER_AT ? _vm.TEXT_FIELD_MAX_LENGTH : null,"rules":_vm.field.required ? _vm.rules.requiredTextField : _vm.rules.optionalTextField,"value":_vm.value},on:{"input":_vm.onInput}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }