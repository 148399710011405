
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitDynamicFieldLabelRow',
	props: {
		field: { type: Object, required: true },
		labelStyles: { type: Object, default: null },
	},
});
