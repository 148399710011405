
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import CreatorsClubPromoBanner from '@/components/PromoBanners/CreatorsClubPromoBanner.vue';

export default Vue.extend({
	name: 'UpsellCreatorsClubDialog',
	props: {
		persistent: { type: Boolean, default: false },
		maxWidth: { type: Number, default: 900 },
	},
	computed: {
		...mapState([
			'upsellDialog',
		]),
	},
	methods: {
		...mapActions([
			'updateUpsellDialog',
		]),
		setOpen (val: boolean) {
			this.updateUpsellDialog({ creatorsClub: !!val });
		},
		onConfirm () {
			if (this.disabled) return;

			this.$emit('on-confirm');
		},
	},
	components: {
		CreatorsClubPromoBanner,
	},
});
