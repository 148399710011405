
import Vue from 'vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import BaseToggle from '@/components/base/BaseToggle.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';

export default Vue.extend({
	name: 'RunnitBooleanField',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: Boolean, default: null },
	},
	data () {
		return {};
	},
	components: {
		BaseToggle,
		RunnitDynamicFieldLabelRow,
	},
});
