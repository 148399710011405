import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mx-4 pb-9 full-width",class:{
		'px-9 pt-7': _vm.$vuetify.breakpoint.mdAndUp,
		'px-5 pt-6': _vm.$vuetify.breakpoint.smAndDown,
	}},[(_vm.localWorkSpaces.length)?_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c(VCardTitle,{staticClass:"pt-0 px-0 mt-0",style:({
				fontSize: _vm.$vuetify.breakpoint.xsOnly ? '17px': '20px',
			})},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"},attrs:{"left":""}},[_vm._v("mdi-database")]),_vm._v(" Spaces ")],1),((
				_vm.isAdmin &&
				_vm.maxWorkspacesReached
			))?_c('info-message-small',{staticClass:"mb-3",staticStyle:{"width":"600px"},attrs:{"super-primary":""}},[_vm._v(" You have reached the "),_c('span',{staticClass:"gradient-text"},[_vm._v("maximum number")]),_vm._v(" of workspaces for your team. Please reach out to "),_c('b',[_vm._v("support@rundiffusion.com")]),_vm._v(" for an increase. ")]):(_vm.isAdmin)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({staticClass:"px-7",attrs:{"disabled":!_vm.isTeamActive,"secondary":""},on:{"click":_vm.openAddWorkspaceDrawer}},'base-button',attrs,false),on),[_vm._v(" Add ")])]}}],null,false,664861950)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Add a new Space with a separate storage volume")])]):_vm._e()],1):_vm._e(),(_vm.loading)?_c('loading-state'):(_vm.hasError)?_c('error-state'):(_vm.localWorkSpaces.length)?_c(VRow,_vm._l((_vm.workspaces),function(workspace){return _c(VCol,{key:workspace.id,staticClass:"mt-4",attrs:{"cols":"12","sm":"4"}},[_c('workspace-card',{attrs:{"workspace":workspace},on:{"on-edit-workspace":_vm.onEditWorkspace}})],1)}),1):_vm._e(),_c('add-edit-workspace',{attrs:{"value":_vm.addEditWorkspaceConfig.open,"is-edit-mode":_vm.addEditWorkspaceConfig.editMode,"workspace":_vm.addEditWorkspaceConfig.workspace},on:{"input":_vm.onAddEditInput,"on-close":function($event){_vm.addEditWorkspaceConfig.workspace = null}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }