
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import ComplexBackgroundBaseStyledMenu from '@/components/designElements/ComplexBackgroundBaseStyledMenu.vue';

export default Vue.extend({
	name: 'BaseStyledMenu',
	props: {
		value: { type: null, default: null },
		/**
		 * Example:
		 *
		 * [
		 * 	{
		 * 	  id: 1,
		 * 	  icon: 'mdi-plus'
		 * 	  label: 'Item 1',
		 * 	  value: 10,
		 *    disabled: true,
		 * 	},
		 * 	{
		 * 	  id: 2,
		 * 	  icon: 'mdi-plus'
		 *    iconStyle: {'color': 'var(--red-800)'}
		 * 	  label: 'Item 2',
		 * 	  value: 20,
		 * 	}
		 * ]
		 */
		menuItems: { type: Array, default: () => [] },
		openOnHover: { type: Boolean, default: false },
		menuWidth: { type: String, default: '318px' },
		itemsAlwaysWhite: { type: Boolean, default: false },
		iconsOnly: { type: Boolean, default: false },
		horizontalMenu: { type: Boolean, default: false },
		closeOnClick: { type: Boolean, default: true },
		bottomSheetPersistent: { type: Boolean, default: false },
	},
	data () {
		return {
		};
	},
	methods: {
		onMenuItemSelect (value: any, close) {
			this.$emit('input', value);
			if (close) {
				close();
			}
		},
		onOpenStateChange (isOpen) {
			this.$emit('is-open', isOpen);
		},
	},
	components: {
		MenuOrBottomSheet,
		ComplexBackgroundBaseStyledMenu,
	},
});
