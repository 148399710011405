
import Vue from 'vue';
import { mapState } from 'vuex';
import { MyBalanceMixin } from '@/mixins';

export default Vue.extend({
	name: 'SaveYourRecurringBalanceAlert',
	mixins: [
		MyBalanceMixin,
	],
	computed: {
		...mapState([
			'user',
			'team',
		]),
		showUserRecurringBalanceExpiringAlert () {
			return !!(
				this.user &&
				!this.user.club &&
				(
					this.isUserRecurringBalanceExpiring1 ||
					this.isUserRecurringBalanceExpiring2
				)
			);
		},
		showTeamRecurringBalanceExpiringAlert () {
			return !!(
				this.team &&
				!this.team.club &&
				(
					this.isTeamRecurringBalanceExpiring1 ||
					this.isTeamRecurringBalanceExpiring2
				)
			);
		},
	},
});
