import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
		'background': _vm.$vuetify.breakpoint.mdAndUp,
		'background background-mobile': _vm.$vuetify.breakpoint.smAndDown,
	}},[(_vm.runnitsEnabled)?_c(VContainer,{staticClass:"pt-10"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"margin":"30px 0 -10px 0"}},[_c('runnits-promo-card',{attrs:{"show-button":""}})],1)])],1)],1):_vm._e(),(!_vm.noNewSessionsAnnouncements.length || _vm.dismissedNoNewSessionsAnnouncements)?_c(VContainer,{staticClass:"pt-10",staticStyle:{"padding-bottom":"140px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('creators-club-promo-teaser'),_c('app-offers-list')],1)],1)],1):_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"py-12 d-flex flex-column align-center"},[_c('div',{staticClass:"styled-container"},[_c('img',{staticClass:"logo",staticStyle:{"margin":"0"},attrs:{"alt":"Logo","height":350,"src":require("../../assets/Service.gif")}}),_c('div',{staticClass:"title-style"},[_vm._v(" No new sessions allowed ")]),_vm._l((_vm.noNewSessionsAnnouncements),function(announcement){return _c('div',{key:announcement.id,staticClass:"message-style"},[_vm._v(" "+_vm._s(announcement.message)+" "),(_vm.user.isAdmin && announcement.allowAdmins)?_c(VBtn,{staticClass:"mt-4",attrs:{"color":"deep-orange","outlined":"","rounded":""},on:{"click":function($event){_vm.dismissedNoNewSessionsAnnouncements = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-account-alert-outline")]),_vm._v(" Click to go through as Admin ")],1):_vm._e()],1)}),_c('join-our-discord',{staticClass:"mt-4 mb-8"}),(_vm.noNewSessionsAnnouncementsButAllowAdmins)?_c('div',{staticClass:"mt-10 full-width d-flex justify-center align-center"},[_c(VBtn,{attrs:{"secondary":""},on:{"click":function($event){_vm.dismissedNoNewSessionsAnnouncements = true}}},[_c(VIcon,[_vm._v("mdi-account-alert-outline")]),_vm._v(" Click to go through as Admin ")],1)],1):_vm._e()],2)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }