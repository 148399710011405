import {
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_NODE_DEF_PROVIDER,
} from '@/constants/enums';

interface DROPDOWN_ITEM {
	id: string | null;
	name: string;
}

export const PROVIDER_ITEMS: DROPDOWN_ITEM[] = [
	{
		id: null,
		name: '---',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG,
		name: '"Runnit" branded image generation pipelines',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RD,
		name: 'RunDiffusion',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.OCTOAI,
		name: 'octo.ai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNPOD,
		name: 'RunPod',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM,
		name: 'Ideogram',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.FALAI,
		name: 'FAL.ai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR,
		name: 'NicheTensor',
	},
];

export const NODE_TYPE_ITEMS: DROPDOWN_ITEM[] = [
	{
		id: null,
		name: '---',
	},
	{
		id: RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
		name: 'Core',
	},
	{
		id: RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
		name: 'Curated',
	},
	{
		id: RUNNIT_NODE_DEF_TOOL_TYPE.TEAM,
		name: 'Team (need to set `runnitNodeDef.teamId`)',
	},
];

export const ENDPOINT_ID_ITEMS: Record<string, DROPDOWN_ITEM[]> = {
	[RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'runnit-img-gen-1',
			name: 'Runnit Ultra (Image Gen)',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RD]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'cmfy-workflow',
			name: '(COMFY) Default Comfy Workflow',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.FALAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'creative-upscaler',
			name: 'Creative Upscaler',
		},
		{
			id: 'aura-sr-upscaler',
			name: 'AuraSR Upscaler',
		},
		{
			id: 'stable-diffusion-xl',
			name: 'Stable Diffusion XL',
		},
		{
			id: 'remove-background',
			name: 'Remove Background',
		},
		{
			id: 'flux-dev',
			name: 'Flux Dev',
		},
		{
			id: 'flux-dev-with-loras',
			name: 'Flux Dev with LoRAs',
		},
		{
			id: 'flux-pro',
			name: 'Flux Pro',
		},
		{
			id: 'flux-schnell',
			name: 'Flux Schnell',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.OCTOAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'stable-diffusion-xl',
			name: 'Stable Diffusion XL',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RUNPOD]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'fooocus',
			name: 'Foooocus',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'generate',
			name: 'Generate',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'dalle',
			name: 'DallE',
		},
	],
};
