import { render, staticRenderFns } from "./ComplexBackgroundBaseStyledMenu.vue?vue&type=template&id=4931dd36&scoped=true&"
import script from "./ComplexBackgroundBaseStyledMenu.vue?vue&type=script&lang=ts&"
export * from "./ComplexBackgroundBaseStyledMenu.vue?vue&type=script&lang=ts&"
import style0 from "./ComplexBackgroundBaseStyledMenu.vue?vue&type=style&index=0&id=4931dd36&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4931dd36",
  null
  
)

export default component.exports