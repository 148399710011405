import Vue from 'vue';
import _get from 'lodash/get';
import { db, functions } from '@/firebase';
import { ROUTER } from '@/router/constants';
import { mapActions, mapState } from 'vuex';
import { Avatar, Runnit, RunnitNodeDef } from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { RUNNITS_ACCESS_LEVEL } from '@/constants/enums';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import _remove from 'lodash/remove';
import _sampleSize from 'lodash/sampleSize';

export const RunnitsCRUDMixin = Vue.extend({
	computed: {
		...mapState([
			'user',
			'team',
			'runnits',
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateLoader',
			'updateSnackbar',
			'updateRunnitState',
		]),
		async onOfferingCardClick (runnitId: string, runnitNodeDef: RunnitNodeDef) {
			if (runnitId && _get(runnitNodeDef, 'id')) {
				const addNodeToRunnitFunctionRef = functions
					.httpsCallable('addNodeToRunnit');
				const { success } = (await addNodeToRunnitFunctionRef({
					runnitId: runnitId,
					nodeDefId: runnitNodeDef.id,
				})).data;

				if (!success) {
					await this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error, problem adding your Runnit, please try again. If the problem continues please reach out to us with a support ticket',
						show: true,
					});
					return;
				}

				await this.updateLoader({
					show: false,
					message: null,
				});
			}
			await this.routerPush(this.$route, this.$router, {
				name: ROUTER.RUNNITS_BOARD,
				params: {
					runnitId: runnitId,
				},
			});
		},
		async createRunnitDraft (runnitNodeDef) {
			const chosenRandomAvatar = await this.getRandomDefaultAvatar();
			const nowDate: Date = new Date();
			return {
				createdAt: nowDate,
				deletedAt: null,
				userId: this.user.id,
				...(this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM ? {
					teamId: this.team.id,
					accessLevel: this.runnitState.runnitsAccessLevel || RUNNITS_ACCESS_LEVEL.PRIVATE,
				} : {
					teamId: null,
					accessLevel: null,
				}),
				title: (runnitNodeDef && runnitNodeDef.title && `My ${runnitNodeDef.title}`) || `Untitled Runnit`,
				description: 'Go make something awesome!',
				...(chosenRandomAvatar ? {
					avatarId: chosenRandomAvatar.id,
					avatar: chosenRandomAvatar,
				} : {})
			};
		},
		canCreateNewRunnit () {
			// TODO: when a paid plan is added, incorporate that into this check

			if (
				this.loadingRunnits ||
				(
					this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
					!this.team
				)
			) {
				return false;
			}

			const tooManyRunnits: boolean = this.runnits.length >= this.MAX_NUM_FREE_RUNNITS;

			if (
				!this.user.isAdmin &&
				tooManyRunnits
			) {
				this.showRunnitsMaxDialog = true;

				return false;
			}

			return true;
		},
		async createNewRunnit (runnitDraft: Runnit, runnitNodeDef: RunnitNodeDef) {
			if (!this.canCreateNewRunnit) return;

			try {
				await this.updateLoader({
					show: true,
					message: `Adding a Runnit${_get(runnitNodeDef, 'title') ? ` with ${_get(runnitNodeDef, 'title')}` : ''}, please wait...`,
				});
				this.addingNewRunnit = true;
				const addedRunnitRef = await db
					.collection('runnits')
					.add({
						...runnitDraft,
						...(runnitDraft.avatarId ? {
							avatarId: runnitDraft.avatarId,
							avatar: db.doc(`avatars/${runnitDraft.avatarId}`)
						} : {
							avatarId: null,
							avatar: null,
						}),
					});
				if (addedRunnitRef && addedRunnitRef.id) {
					await this.onOfferingCardClick(addedRunnitRef.id, runnitNodeDef);
				}
			} catch (e) {
				console.error('Error adding runnit', e);

				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error, problem adding your Runnit, please try again. If the problem continues please reach out to us with a support ticket',
					show: true,
				});
			} finally {
				this.addingNewRunnit = false;
				
				this.updateRunnitState({
					runnitDraft: null,
					runnitDraftNodeRef: null,
				});

				await this.updateLoader({
					show: false,
					message: null,
				});
			}
		},
		async onAddRunnitClick (runnitNodeDef: RunnitNodeDef) {
			if (!this.canCreateNewRunnit) return;

			const runnitDraft = await this.createRunnitDraft(runnitNodeDef);

			this.updateRunnitState({
				runnitDraft,
				runnitDraftNodeRef: runnitNodeDef,
				runnit: null,
			});
		},
		async getRandomDefaultAvatar () {
			const avatars = [];
			try {
				const avatarsRef = db.collection('avatars')
					.where('deletedAt', '==', null)
					.where('userId', '==', 'DEFAULT')
					.where('useCase', '==', 'RUNNIT_LIB')
					.orderBy('name', 'asc');
	
				(await avatarsRef.get()).forEach(async (doc: any) => {
					avatars.push({
						...doc.data(),
						get id () { return doc.id },
					} as Avatar);
				});
			} catch (err) {
				console.error('failed to get default avatar images', err);
			}
			return avatars.length ? _sampleSize(avatars, 1)[0] : null;
		}
	},
});
