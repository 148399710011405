
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import { APP_BAR_HEIGHT } from '@/components/Toolbar/constants';
import RunnitSettings from '@/views/Runnits/RunnitSettings.vue';
import AccountMenuBtn from '@/components/Toolbar/AccountMenuBtn.vue';
import RunnitPageSettings from '@/views/Runnits/RunnitPageSettings.vue';
import GetSupportBtn from '@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue';
import ImageGalleryDrawer from '@/components/ImageGallery/ImageGalleryDrawer.vue';
import { RunnitsAndRunnitNodeDefsBindingsMixin } from '@/mixins/RunnitsAndRunnitNodeDefsBindingsMixin';
import RunnitAccountSelectionDialog from '@/views/Runnits/RunnitAccountSelectionDialog.vue';
import ComplexBackgroundHeader from '@/components/designElements/ComplexBackgroundHeader.vue';
import { RunnitState } from '@/store';
import WhiteTransparentChip from '@/components/WhiteTransparentChip.vue';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import pluralize from 'pluralize';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';
import GreyButton from '@/components/base/GreyButton.vue';
import { RUNNITS_OWNER_SELECTION } from './constants';

export default Vue.extend({
	name: 'RunnitsApp',
	mixins: [
		RunnitsAndRunnitNodeDefsBindingsMixin,
		RunnitsImageSelectMixin,
		RunnitBulkActionsMixin,
	],
	data () {
		return {
			APP_BAR_HEIGHT,

			deleteImagesDialog: {
				open: false,
				deleting: false,
			},
		};
	},
	created () {
		this.updateToolbar({
			insideRunnits: true,
		});
	},
	watch: {
		'runnitState.runnitsOwnerSelection': {
			immediate: true,
			async handler (newVal, oldVal) {
				if (oldVal && newVal !== oldVal) {
					this.routerPush(this.$route, this.$router, {
						name: ROUTER.RUNNITS_HOME,
					});
				}
			}
		},
		'runnitState.runnit': {
			immediate: true,
			async handler (newVal, oldVal) {
				const ownerSelection = this.runnitState.runnitsOwnerSelection;
				if (
					newVal &&
					oldVal &&
					(
						ownerSelection &&
						(
							ownerSelection === RUNNITS_OWNER_SELECTION.USER &&
							newVal.teamId !== null
						) ||
						(
							ownerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
							this.team.id !== newVal.teamId
						)
					)
				) {
					this.routerPush(this.$route, this.$router, {
						name: ROUTER.RUNNITS_HOME,
					});
				}
			}
		},
		bulkDeleteConfirmDialogOpen: {
			immediate: true,
			async handler (newVal: boolean, oldVal: boolean) {
				if (newVal !== oldVal && !newVal && this.$refs.doubleConfirmDangerDialog) {
					this.$refs.doubleConfirmDangerDialog.reset();
				}
			}
		}
	},
	computed: {
		...mapState([
			'user',
			'runnitState',
			'runnits',
			'isAdminProxyingUser',
			'team',
		]),
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'updateRunnitState',
		]),
		pluralize,
		onBackClick () {
			if (!this.$route.meta.isSessionPage) {
				this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_HOME });
			}
		},
		onGalleryDrawerClose (open: boolean) {
			this.updateRunnitState({
				imageGalleryDrawerOpen: open,
			});
		},
	},
	components: {
		GreyButton,
		WhiteTransparentChip,
		ComplexBackgroundHeader,
		RunnitAccountSelectionDialog,
		GetSupportBtn,
		RunnitSettings,
		ImageGalleryDrawer,
		AccountMenuBtn,
		RunnitPageSettings,
		DoubleConfirmDangerDialog,
	},
});
