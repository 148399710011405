import Vue from 'vue';
import { mapState } from 'vuex';
import {
	calcRealtimeBalanceInfo,
} from '@run-diffusion/shared';

export const MyBalanceMixin = Vue.extend({
	computed: {
		...mapState([
			'realTimeMoment',
			'user',
			'team',
			'userBalanceAccount',
			'teamBalanceAccount',
		]),
		userRunnitTokens () {
			if (
				this.user &&
				this.userBalanceAccount
			) {
				const { realtimeRunnitTokens } = calcRealtimeBalanceInfo(this.userBalanceAccount);
				return realtimeRunnitTokens;
			}
			return 0;
		},
		teamRunnitTokens () {
			if (
				this.team &&
				this.teamBalanceAccount
			) {
				const { realtimeRunnitTokens } = calcRealtimeBalanceInfo(this.teamBalanceAccount);
				return realtimeRunnitTokens;
			}
			return 0;
		},
		userRecurringBalance () {
			if (
				this.user &&
				this.userBalanceAccount
			) {
				const { realtimeRecurringBalance } = calcRealtimeBalanceInfo(this.userBalanceAccount);
				return realtimeRecurringBalance;
			}
			return 0;
		},
		teamRecurringBalance () {
			if (
				this.team &&
				this.teamBalanceAccount
			) {
				const { realtimeRecurringBalance } = calcRealtimeBalanceInfo(this.teamBalanceAccount);
				return realtimeRecurringBalance;
			}
			return 0;
		},
		isUserRecurringBalanceExpiring1 () {
			return !!(
				this.user &&
				this.userBalanceAccount &&
				!this.user.club &&
				this.userRecurringBalance &&
				this.userBalanceAccount.recurringExpiresAt1 &&
				this.realTimeMoment.isBefore(this.userBalanceAccount.recurringExpiresAt1.toMillis())
			);
		},
		isUserRecurringBalanceExpiring2 () {
			return !!(
				!this.isUserRecurringBalanceExpiring1 &&
				this.user &&
				this.userBalanceAccount &&
				!this.user.club &&
				this.userRecurringBalance &&
				this.userBalanceAccount.recurringExpiresAt2 &&
				this.realTimeMoment.isBefore(this.userBalanceAccount.recurringExpiresAt2.toMillis())
			);
		},
		isTeamRecurringBalanceExpiring1 () {
			return !!(
				this.team &&
				this.teamBalanceAccount &&
				!this.team.isActive &&
				this.teamRecurringBalance &&
				this.teamBalanceAccount.recurringExpiresAt1 &&
				this.realTimeMoment.isBefore(this.teamBalanceAccount.recurringExpiresAt1.toMillis())
			);
		},
		isTeamRecurringBalanceExpiring2 () {
			return !!(
				!this.isTeamRecurringBalanceExpiring1 &&
				this.team &&
				this.teamBalanceAccount &&
				!this.team.isActive &&
				this.teamRecurringBalance &&
				this.teamBalanceAccount.recurringExpiresAt2 &&
				this.realTimeMoment.isBefore(this.teamBalanceAccount.recurringExpiresAt2.toMillis())
			);
		},
		isUserRecurringBalanceActive () {
			return !!(
				this.user &&
				this.user.club &&
				this.userBalanceAccount
			);
		},
		isTeamRecurringBalanceActive () {
			return !!(
				this.team &&
				!this.team.isOnboarding &&
				this.team.isActive &&
				this.teamBalanceAccount
			);
		},
		computedUserBalance () {
			const { realtimeBalance } = calcRealtimeBalanceInfo(this.userBalanceAccount);
			let result: number = realtimeBalance;

			if (this.isUserRecurringBalanceActive) {
				result += this.userRecurringBalance;
			}

			return result > 0 ? result : 0;
		},
		computedTeamBalance () {
			const { realtimeBalance } = calcRealtimeBalanceInfo(this.teamBalanceAccount);
			let result: number = realtimeBalance;

			if (this.isTeamRecurringBalanceActive) {
				result += this.teamRecurringBalance;
			}

			return result > 0 ? result : 0;
		},
	},
});
