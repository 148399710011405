
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { db, storage } from '@/firebase';
import { NODE_RUN_RESULT_SELECTION_MODE } from '@/views/Runnits/constants';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import ListItem from '@/components/base/ListItem.vue';
import { RunnitState } from '@/store';
import { RunnitNodeField, RunnitNodeRunInputValue, RunnitNodeRunResult, RunnitUpload } from '@run-diffusion/shared';
import _isEqual from 'lodash/isEqual';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { UPLOAD_FILE_TYPE } from '@/constants/enums';
import ImageInputSelectedImageContainer from '@/views/Runnits/RunnitSettings/ImageInput/ImageInputSelectedImageContainer.vue';
import { IMAGE_GALLERY_DIALOG_NAV, ImageGalleryDialogNav } from '@/components/ImageGallery/utils';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';

type ImgToImgSelection = 'NONE' | 'SELECT' | 'UPLOAD';
export default Vue.extend({
	name: 'ImageInput',
	mixins: [
		RunnitDynamicFieldMixin,
		RunnitsImageSelectMixin,
	],
	props: {
		field: { type: Object, required: true },
		value: { type: Object, default: null },
	},
	data () {
		const IMG_TO_IMG: Record<ImgToImgSelection, ImgToImgSelection> = {
			NONE: 'NONE',
			SELECT: 'SELECT',
			UPLOAD: 'UPLOAD',
		};

		return {
			IMAGE_GALLERY_DIALOG_NAV,
			FILE_SIZE_LIMIT: 4194304, // 4 MB in bytes
			IMG_TO_IMG,

			imgToImgSelection: IMG_TO_IMG.NONE,

			isUploading: false,
			isBeingDraggedOver: false,
		};
	},
	destroyed () {
		this.exitSelectionMode(this.selectionStateTriggerId);
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
		showNoneOptionActionSection () {
			return this.imgToImgSelection === this.IMG_TO_IMG.NONE;
		},
		showSelectOptionActionSection () {
			return this.imgToImgSelection === this.IMG_TO_IMG.SELECT;
		},
		showUploadOptionActionSection () {
			return this.imgToImgSelection === this.IMG_TO_IMG.UPLOAD;
		},
		selectionStateTriggerId () {
			return `image-input-${this.field.uuid}`;
		}
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: RunnitNodeRunInputValue, oldVal: RunnitNodeRunInputValue) {
				if (!oldVal && newVal && this.imgToImgSelection === this.IMG_TO_IMG.NONE) {
					this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
				}

				if (newVal && newVal.nodeRunResult) {
					this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
				}
			},
		},
		field: {
			immediate: true,
			handler (newVal: RunnitNodeField) {
				if (newVal && newVal.required && this.imgToImgSelection === this.IMG_TO_IMG.NONE) {
					this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
				}
			},
		},
		imgToImgSelection: {
			immediate: true,
			handler (newVal: ImgToImgSelection, oldVal: ImgToImgSelection) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case this.IMG_TO_IMG.NONE:
						default:
							if (this.value) this.onInput(null);
							this.exitSelectionMode(this.selectionStateTriggerId);
							break;
						case this.IMG_TO_IMG.UPLOAD:
							if (this.value) this.onInput(null);
							break;
						case this.IMG_TO_IMG.SELECT:
							if (this.value && this.value.upload && oldVal === this.IMG_TO_IMG.UPLOAD) {
								this.onInput(null);
							}
							if (!this.value) {
								this.updateRunnitState({
									imageGalleryDialogOpen: false,
								});
								this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field);
							}
							break;
					}
				}
			},
		},
		runnitState: {
			immediate: false,
			handler (newVal: RunnitState, oldVal: RunnitState) {
				if (
					newVal !== oldVal &&
					newVal.selectionStateHistory[this.selectionStateTriggerId] &&
					newVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection &&
					newVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection.uuid === this.field.uuid
				) {
					const newResultUuidsList: string[] = newVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults.map(({ uuid }) => uuid);
					const oldResultUuidsList: string[] = oldVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults.map(({ uuid }) => uuid);
					const newUploadIdList: string[] = newVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads.map(({ id }) => id);
					const oldUploadIdList: string[] = oldVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads.map(({ id }) => id);
					if (
						(
							!oldVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection ||
							oldVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection.uuid !== this.field.uuid
						) ||
						!_isEqual(newResultUuidsList, oldResultUuidsList) ||
						!_isEqual(newUploadIdList, oldUploadIdList)
					) {
						const valueNodeRunResultUuid: string = this._get(this.value, 'nodeRunResult.uuid') || null;
						const valueUploadId: string = this._get(this.value, 'upload.id') || null;
						const firstSelectedNodeRunResult: RunnitNodeRunResult = this._get(newVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults, '[0]') || null;
						const firstSelectedUpload: RunnitUpload = this._get(newVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads, '[0]') || null;

						if (!firstSelectedNodeRunResult && !firstSelectedUpload && (valueNodeRunResultUuid || valueUploadId)) {
							this.onInput(null);
						} else if (firstSelectedNodeRunResult && firstSelectedNodeRunResult.uuid !== valueNodeRunResultUuid) {
							this.onInput({ nodeRunResult: firstSelectedNodeRunResult });
						} else if (firstSelectedUpload && firstSelectedUpload.id !== valueUploadId) {
							this.onInput({ upload: firstSelectedUpload });
						}
					}
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		onInput (val: RunnitNodeRunInputValue) {
			this.$emit('input', val);
		},
		clearValueAndStartSelectSelectionMode () {
			this.onInput(null);
			this.clearSelectionsFromState(this.selectionStateTriggerId);
			this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field);
		},
		startLibrarySelectionMode (imageGalleryDialogInitialNav: ImageGalleryDialogNav) {
			this.updateRunnitState({
				imageGalleryDialogOpen: true,
				imageGalleryDialogInitialNav: imageGalleryDialogInitialNav,
			});
			this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field);
		},
		onUploadSelection () {
			this.imgToImgSelection = this.IMG_TO_IMG.UPLOAD;
		},
		handleDragOver (ev) {
			if (this.isUploading) return;
			this.isBeingDraggedOver = true;
			ev.preventDefault();
		},
		handleDragLeave (ev) {
			if (this.isUploading) return;
			this.isBeingDraggedOver = false;
			ev.preventDefault();
		},
		async handleDrop (ev) {
			if (this.isUploading) return;
			this.isBeingDraggedOver = false;
			let dt = ev.dataTransfer;
			let files = dt.files;
			await this.handleFiles(files);
		},
		async onHiddenFileInputChange (ev) {
			if (this.isUploading) return;
			this.isBeingDraggedOver = false;
			await this.handleFiles(ev.target.files);

			// Clear the selected file from the input
			this.$refs.fileInput.value = ''; // Reset the value of the input element
		},
		openFileDialog () {
			if (this.isUploading) return;
			this.$refs.fileInput.click();
		},
		async handleFiles (files) {
			this.isBeingDraggedOver = false;

			// Pull off first file
			const file = this._get([...files], '[0]') || null;
			if (!file) return;

			// Size check files
			if (file.size >= this.FILE_SIZE_LIMIT) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Image to upload must be less than 4 megabytes`,
					show: true,
				});
				return;
			}

			// Do uploading
			try {
				this.isUploading = true;
				const upload: RunnitUpload = await this.uploadFile(file);
				this.onInput({ upload });
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error uploading image`,
					show: true,
				});
			} finally {
				this.isUploading = false;
			}
		},
		async uploadFile (file) {
			let upload: RunnitUpload = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const uploadsRef = db.collection(`runnitUploads`)
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.limit(1);
			(await uploadsRef.get()).forEach(async (doc: any) => {
				upload = {
					...doc.data(),
					get id () { return doc.id },
				} as RunnitUpload;
			});

			if (!upload) {
				// Upload file
				const { name, type, size } = file;
				const fileRef = storage.ref(`runnitUploads/users/${this.user.id}/uploads/${name}`);

				try {
					// Exists
					await fileRef.getDownloadURL();
				} catch (e) {
					// Not exists
					await fileRef.put(file);
				}

				// Record upload file in database
				const nowDate: Date = new Date();
				const addedUploadRef = await db.collection('runnitUploads')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: name,
						contentType: type,
						size: size,
						hashHex,
					});
				upload = {
					...(await addedUploadRef.get()).data(),
					get id () { return addedUploadRef.id },
				} as RunnitUpload;
			}

			return upload;
		},
	},
	components: {
		ImageInputSelectedImageContainer,
		ListItem,
		RunnitSettingHelperBtn,
		RunnitDynamicFieldLabelRow,
	},
});
