
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { RunnitsOwnerSelection } from '@/store';
import { setRunnitsOwnerSelection } from '@/utils';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import GlassButton from '@/components/base/GlassButton.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import RunnitsOwnerSelectionPicker from '@/views/Runnits/RunnitsOwnerSelectionPicker/RunnitsOwnerSelectionPicker.vue';

export default Vue.extend({
	name: 'RunnitAccountSelectionDialog',
	props: {
		isSideNavDesign: { type: Boolean, default: false },
	},
	data () {
		return {
			RUNNITS_OWNER_SELECTION,
			accountSelectionDialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'team',
			'runnitState',
		]),
		runnitsOwnerSelection () {
			return this.runnitState ? this.runnitState.runnitsOwnerSelection : null;
		},
		runnitsOwnerSelectionLabel () {
			let label: string = '';

			if (this.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER) {
				label = 'Personal';
			} else if (this.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM) {
				label = this.team ? this.team.name : 'No team';
			}

			return label;
		},
		firstTwoLettersOfTeamName () {
			if (this.team) {
				let firstToLetters = this.team.name.slice(0,2);
				return firstToLetters.charAt(0).toUpperCase() + firstToLetters.slice(1).toLowerCase();
			}

			return '';
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		onEditRunnitSettings () {
			this.accountSelectionDialogOpen = true;
		},
		onRunnitsOwnerSelectionChange (runnitsOwnerSelection: RunnitsOwnerSelection) {
			setRunnitsOwnerSelection(runnitsOwnerSelection);
			this.updateRunnitState({
				runnitsOwnerSelection,
			});
			this.accountSelectionDialogOpen = false;
		},
	},
	components: {
		GlassButton,
		ComplexBackground,
		RunnitsOwnerSelectionPicker,
	},
});
