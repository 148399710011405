
import Vue from 'vue';
import { mapState } from 'vuex';
import _truncate from 'lodash/truncate';
import { TeamUserMixin } from '@/mixins';
import { Workshop } from '@run-diffusion/shared';
import ErrorState from '@/components/states/ErrorState.vue';
import WorkshopCard from '@/views/Workshops/WorkshopCard.vue';
import AddEditWorkshop from '../Workshops/AddEditWorkshop.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import InfoMessageSmall from '@/components/base/InfoMessageSmall.vue';

export default Vue.extend({
	name: 'Workshops',
	mixins: [
		TeamUserMixin,
	],
	data () {
		return {
			MAX_WORKSHOPS: 200,
			loading: false, // TODO: loading state with Vuex
			hasError: false, // TODO: error state with Vuex

			addEditWorkshopConfig: {
				open: false,
				editMode: false,
				workshop: null,
			},

			localWorkshops: [],
		};
	},
	computed: {
		...mapState([
			'team',
			'workshops',
		]),
		maxWorkshopsReached () {
			return this.localWorkshops.length >= this.MAX_WORKSHOPS;
		},
		workshopsEnabled () {
			return this._get(this.team, 'features.workshops');
		},
	},
	watch: {
		workshops: {
			immediate: true,
			handler (newVal: Workshop[]) {
				this.localWorkshops = newVal || [];
			},
		},
	},
	methods: {
		_truncate,
		openAddWorkshopDrawer () {
			this.addEditWorkshopConfig.open = true;
			this.addEditWorkshopConfig.editMode = false;
			this.addEditWorkshopConfig.workshop = null;
		},
		onAddEditInput (open) {
			this.addEditWorkshopConfig.open = open;
			this.addEditWorkshopConfig.workshop = null;
		},
		onEditWorkshop (workshop) {
			this.addEditWorkshopConfig.open = true;
			this.addEditWorkshopConfig.editMode = true;
			this.addEditWorkshopConfig.workshop = workshop;
		},
	},
	components: {
		WorkshopCard,
		InfoMessageSmall,
		AddEditWorkshop,
		ErrorState,
		LoadingState,
	},
});
