
import Vue from 'vue';
import DialogContent from '@/components/base/DialogContent.vue';

export default Vue.extend({
	name: 'DoubleConfirmDangerDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: Number, default: 500 },
		disabled: { type: Boolean, default: false },
		actionDisabled: { type: Boolean, default: false },
		confirmLoading: { type: Boolean, default: false },
		hideCancel: { type: Boolean, default: false },
		title: { type: String, required: true },
		bodyText: { type: String, default: null },
		cancelText: { type: String, default: 'Cancel' },
		actionText: { type: String, default: 'Delete' },
		contentStyles: { type: String, default: null },
	},
	data () {
		return {
			open: false,
			loading: false,
			doubleConfirmTitle: null,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	methods: {
		reset () {
			this.loading = false;
			this.open = false;
			this.doubleConfirmTitle = null;
		},
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onConfirm () {
			if (this.disabled || this.actionDisabled) return;

			this.$emit('on-confirm');
		},
		onCancel () {
			this.reset();
			this.setOpen(false);
			this.$emit('on-cancel');
		},
		showDoubleConfirmText () {
			this.doubleConfirmTitle = 'Caution: This action may not be desired!';
		},
		onDeleteAnyway () {
			this.loading = true;
			this.$emit('on-confirm', this.reset);
		},
	},
	components: {
		DialogContent,
	},
});
