import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-info-fields-outer-container",style:({
			...(!_vm.insideNode && {
				margin: '0 0 10px 0',
			}),
			...(_vm.insideNode && {
				margin: '0 0 20px 0',
			}),
		})},[_c('div',{staticClass:"image-attribution",style:({
				...(!_vm.insideNode && {
					margin: '30px 0 12px 0',
				}),
				...(_vm.insideNode && {
					margin: '30px 0 -12px 0',
				}),
			})},[_c('div',{staticClass:"first-letter-of-email"},[_vm._v(" "+_vm._s(_vm.firstLetterOfEmail)+" ")]),_c('div',{staticClass:"image-creator-label"},[_vm._v(" "+_vm._s(_vm.nodeRun.email)+" ")])]),_c('div',{staticClass:"image-info-fields-top-section",style:({
				...(!_vm.insideNode && {
					background: 'var(--grey-875)',
				}),
				...(_vm.insideNode && {
					background: 'var(--grey-875)',
					margin: '25px -6px 0 -6px',
				}),
				...(_vm.isFirstIndex || _vm.insideCarousel && {
					marginTop: '10px !important',
				}),
			})},[(_vm.numImgs)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"image-info-number-images"},on),[_c(VIcon,{staticStyle:{"font-size":"16px","color":"var(--grey-500)"}},[_vm._v("mdi-image-multiple-outline")]),_c('div',{staticClass:"image-info-number-images-number"},[_vm._v(" "+_vm._s(_vm.numImgs)+" ")])],1)]}}],null,false,2901242283)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" "+_vm._s(_vm.numImgs)+" generations in batch ")])]):_vm._e(),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.nodeRun.createdAt.toMillis(),'MMM D, YYYY [@] hh:mm a'))+" ")])],1),_c('div',{staticClass:"image-info-fields",style:({
				...(!_vm.insideNode && {
					background: 'var(--grey-875)',
				}),
				...(_vm.insideNode && {
					background: 'var(--grey-900)',
				}),
			})},_vm._l((_vm.nodeRun.fields),function(field){return _c('div',{key:field.uuid,staticClass:"image-info-field",style:({
					...([_vm.RUNNIT_NODE_FIELD_TYPE.PROMPT, _vm.RUNNIT_NODE_FIELD_TYPE.NEG_PROMPT].includes(field.type) && {
						width: _vm.determineWidth(),
						padding: '0',
					}),
				})},[_c('div',{staticClass:"image-info-field-label"},[_c('div',[_vm._v(" "+_vm._s(field.label)+" ")])]),(field.type === _vm.RUNNIT_NODE_FIELD_TYPE.CHECKPOINT)?_c('runnit-model-selection'):_c('div',[_c('div',{staticClass:"image-info-field-value"},[(_vm.nodeRun.inputs[field.fieldDefUuid])?_c('span',[_vm._v(" "+_vm._s(_vm._truncate(_vm.nodeRun.inputs[field.fieldDefUuid], { length: _vm.fieldValueTruncateCharCount }))+" ")]):_c('span',{staticClass:"image-info-field-value-empty"},[_vm._v(" none ")])])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }