
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
	CHECKOUT_CREATORS_CLUB_MEMBERSHIP,
} from '@run-diffusion/shared';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';

export default Vue.extend({
	name: 'BuyCreatorsClubButton',
	props: {
		btnProps: { type: Object, default: () => ({}) },
	},
	data () {
		return {
			isAnnual: false,
		};
	},
	computed: {
		...mapGetters([
			'showStripeUserMembershipPortalBtn',
		]),
		...mapState([
			'user',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateLoader',
			'goToStripeSubscriptionPortal',
		]),
		async onBuyClubMembership () {
			const routeData = this.$router.resolve({
				name: 'GoToPayment',
				query: {
					checkoutAction: CHECKOUT_CREATORS_CLUB_MEMBERSHIP,
					...(this.isAnnual && {
						annual: '1',
					}),
				},
			});
			window.open(routeData.href, '_blank');
		},
	},
	components: {
		ConfirmDialog,
	},
});
