
import Vue from 'vue';
import { mapState } from 'vuex';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import {
	RunnitsCRUDMixin,
	RunnitsAndRunnitNodeDefsBindingsMixin,
} from '@/mixins';
import OfferingCard from '@/components/OfferingCard.vue';

export default Vue.extend({
	name: 'RunnitPrivatePage',
	mixins: [
		RunnitsCRUDMixin,
		RunnitsAndRunnitNodeDefsBindingsMixin,
	],
	props: {},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
		};
	},
	computed: {
		...mapState([
			'user',
			'runnits',
			'runnitsMap',
			'runnitState',
			'loadingRunnits',
		]),
	},
	components: {
		OfferingCard,
	},
});
