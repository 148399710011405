import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('base-button',{attrs:{"css-class":{
			'pl-4 pr-5': _vm.longFormBtnText,
		},"small":"","call-to-action":_vm.callToAction,"secondary":_vm.secondary},on:{"click":_vm.onJoinClick}},[_c('DiscordSVG',{attrs:{"height":25,"width":25,"fill":"#6f86ff"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"pl-1",style:({
				...(_vm.boldBtnText && {
					'font-weight': 'bold',
				}),
			})},[(_vm.discordNotLinked)?_c('span',[_vm._v("Link Your Discord!")]):(_vm.longFormBtnText)?_c('span',[_vm._v("Join Our Discord!")]):_c('span',[_vm._v("Discord")])]):_vm._e()],1),_c(VDialog,{attrs:{"max-width":"600","value":_vm.dialogOpen}},[_c(VSheet,{staticClass:"d-flex flex-column justify-center align-center pa-12"},[_c('img',{attrs:{"alt":"Creator's Club Discord Channels","width":_vm.$vuetify.breakpoint.smAndUp ? '170px' : '140px',"src":require("./assets/animated-discord.gif")}}),_c('div',{staticClass:"text-subtitle-1 my-6 text-left",staticStyle:{"max-width":"550px","color":"var(--grey-500)"}},[_c('div',{staticStyle:{"font-size":"16px","color":"var(--grey-600)","max-width":"470px","text-align":"center"},style:({
						fontSize: _vm.$vuetify.breakpoint.smAndUp ? '16px' : '14px',
					})},[_vm._v(" As a member of the "),_c('span',{staticClass:"gradient-text"},[_vm._v("Creator's Club")]),_vm._v(" you can "),_c('b',[_vm._v("link")]),_vm._v(" your Discord to get access to the following discord channels below: ")]),_c('div',{staticClass:"d-flex justify-center mt-10"},[_c('img',{staticClass:"tw-shadow-XL",staticStyle:{"border-radius":"8px"},attrs:{"alt":"Creator's Club Discord Channels","width":_vm.$vuetify.breakpoint.smAndUp ? '220px' : '170px',"src":require("./assets/creators-club-channels.png")}})])]),_c('div',{staticClass:"my-7 d-flex justify-center"},[_c('base-button',{staticClass:"mr-6",attrs:{"text":""},on:{"click":function($event){_vm.dialogOpen = false}}},[_vm._v(" Close ")]),_c('base-button',{staticStyle:{"min-width":"120px"},on:{"click":_vm.onLinkDiscord}},[_vm._v(" Link ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }