import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex full-width justify-space-between"},[_c('div',[_c(VSlideXReverseTransition,[(_vm.value)?_c('runnit-setting-helper-btn',{staticClass:"mr-2 ml-4",attrs:{"min-width":"35px","disabled":_vm.loadingPrompt,"tooltip":"Clear prompt"},on:{"click":_vm.clearPrompt}},[_c(VIcon,{staticStyle:{"font-size":"18px","color":"var(--grey-450)"}},[_vm._v(" mdi-cancel ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('prompt-generator',{attrs:{"prompt-value":_vm.value,"prompt-field":_vm.field},on:{"on-use-enhanced-prompt":_vm.onUseEnhancedPrompt},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('runnit-setting-helper-btn',{staticClass:"mr-2",attrs:{"min-width":"35px","disabled":_vm.loadingPrompt,"loading":_vm.loadingPrompt,"tooltip":"Enhance your prompt"},on:{"click":_vm.openPromptGenerator}},[_c(VIcon,{staticClass:"gradient-text",staticStyle:{"font-size":"18px","color":"var(--grey-450)"}},[_vm._v(" mdi-auto-fix ")])],1)]},proxy:true}])}),_c('runnit-setting-helper-btn',{attrs:{"min-width":"35px","disabled":_vm.loadingPrompt,"loading":_vm.loadingPrompt,"tooltip":"Generate random prompt"},on:{"click":_vm.handleGenerateRandomPrompt}},[_c(VIcon,{staticStyle:{"font-size":"18px","color":"var(--grey-450)"}},[_vm._v(" mdi-dice-multiple-outline ")])],1)],1)])]},proxy:true}])}),_c(VTextarea,{class:{
			required: !!_vm.field.required,
		},attrs:{"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"dense":"","filled":"","flat":"","solo-inverted":"","auto-grow":"","rows":"3","loading":_vm.loadingPrompt,"disabled":_vm.loadingPrompt,"hide-details":"auto","counter":_vm.value && _vm.value.length >= _vm.SHOW_TEXT_FIELD_COUNTER_AT ? _vm.TEXT_FIELD_MAX_LENGTH : null,"rules":_vm.field.required ? _vm.rules.requiredTextField : _vm.rules.optionalTextField,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }