<template>
	<v-card>
		<v-card-title class="dialog-title-container">
			<div class="dialog-title">
				{{ title }}
				<slot name="after-title"></slot>
			</div>
			<div class="d-flex align-center">
				<slot name="title-actions"></slot>
				<base-button
					:disabled="closeDisabled"
					secondary
					icon
					small
					@click="onCancel"
				>
					<v-icon small>mdi-close</v-icon>
				</base-button>
			</div>
		</v-card-title>
		<v-card-text :style="contentStyles">
			<div class="d-flex flex-column justify-space-between full-height">
				<div :class="contentWrapperClassComputed">
					<slot name="default"></slot>
				</div>
				<div :class="actionsWrapperClass">
					<slot name="actions"></slot>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'DialogContent',
	props: {
		closeDisabled: { type: Boolean, default: false },
		title: { type: String, default: '' },
		contentStyles: { type: [String, Object], default: 'height: 65vh' },
		contentWrapperClass: { type: [String, Object], default: null },
		actionsWrapperClass: { type: [String, Object], default: 'px-6 mb-2' },
	},
	methods: {
		onCancel () {
			this.$emit('on-cancel');
		},
	},
	computed: {
		contentWrapperClassComputed () {
			if (this.contentWrapperClass) {
				return this.contentWrapperClass;
			} else if (!this.contentWrapperClass && this.$vuetify.breakpoint.xsOnly) {
				return 'px-3 pt-3 d-flex flex-column';
			} else {
				return 'px-6 pt-6 d-flex flex-column';
			}
		},
	},
});
</script>

<style lang="scss" scoped>
	.dialog-title-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding: 28px 30px !important;
		background: var(--grey-950);
		background-image: radial-gradient(circle at -5% -90%, var(--grey-875), var(--grey-950) 75%) !important;
		box-shadow: inset 0 -11px 19px -6px rgba(0, 0, 0, 0.8);
	}
</style>
