import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dashboard-wrapper"},[((
			_vm.teamReportEmbedUrlsDataLoading ||
			!_vm.teamMetricsDashboardUrl
		))?_c('div',{staticClass:"dashboard-loading"},[_c('div',{staticClass:"d-flex full-width justify-center align-center flex-column"},[_c(VProgressCircular,{staticClass:"mb-8",attrs:{"indeterminate":true,"size":"120","width":"2","color":"#4d4d4d"}}),_c('div',{staticStyle:{"font-size":"18px","color":"var(--grey-650)"}},[_vm._v(" Dashboard loading... ")])],1)]):_c('div',{staticClass:"full-width",class:{
			'dashboard-xs': _vm.$vuetify.breakpoint.xsOnly,
			'dashboard-sm': _vm.$vuetify.breakpoint.smOnly,
			'dashboard-md': _vm.$vuetify.breakpoint.mdOnly,
			'dashboard-lg': _vm.$vuetify.breakpoint.lgOnly,
			'dashboard-xl': _vm.$vuetify.breakpoint.xlOnly,
		}},[(_vm.teamMetricsDashboardUrl)?_c('iframe',{directives:[{def: Resize,name:"resize",rawName:"v-resize.quiet",value:({ log: true }),expression:"{ log: true }",modifiers:{"quiet":true}}],attrs:{"src":_vm.teamMetricsDashboardUrl,"frameborder":"0","width":"100%","height":"100%","allowtransparency":""}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }