
import Vue from 'vue';
import { RUNNIT_NODE_FIELD_TYPE } from '@/constants/enums';
import Prompt from '@/views/Runnits/RunnitSettings/Prompt.vue';
import WidthHeightField from '@/views/Runnits/RunnitSettings/WidthHeightField.vue';
import ImageInput from '@/views/Runnits/RunnitSettings/ImageInput/ImageInput.vue';
import NegativePrompt from '@/views/Runnits/RunnitSettings/NegativePrompt.vue';
import RunnitFieldHeader from '@/views/Runnits/RunnitSettings/RunnitFieldHeader.vue';
import CfgSlider from '@/views/Runnits/RunnitSettings/CfgSlider.vue';
import StepsSlider from '@/views/Runnits/RunnitSettings/StepsSlider.vue';
import SeedInput from '@/views/Runnits/RunnitSettings/SeedInput.vue';
import RunnitTextField from '@/views/Runnits/RunnitSettings/RunnitTextField.vue';
import RunnitBooleanField from '@/views/Runnits/RunnitSettings/RunnitBooleanField.vue';
import RunnitSingleSelect from '@/views/Runnits/RunnitSettings/RunnitSingleSelect.vue';
import RunnitBaseSlider from '@/views/Runnits/RunnitSettings/RunnitBaseSlider.vue';
import { RunnitNodeField } from '@run-diffusion/shared';
import RunnitModelSelector from '@/views/Runnits/RunnitSettings/RunnitModelSelector.vue';

export default Vue.extend({
	name: 'RunnitDynamicField',
	props: {
		field: { type: Object, required: true },
		value: { type: [Object, String, Number, Boolean, Array], default: null },
		index: { type: [String, Number], default: null },
	},
	data () {
		return {
			RUNNIT_NODE_FIELD_TYPE,
		};
	},
	computed: {
		isFirstIndex () {
			return +this.index === 0;
		},
	},
	methods: {
		onFieldInput (field: RunnitNodeField, val: any) {
			this.$emit('input', field, val);
		},
	},
	components: {
		RunnitModelSelector,
		RunnitBaseSlider,
		RunnitSingleSelect,
		RunnitBooleanField,
		RunnitTextField,
		SeedInput,
		StepsSlider,
		CfgSlider,
		Prompt,
		ImageInput,
		WidthHeightField,
		NegativePrompt,
		RunnitFieldHeader,
	},
});
