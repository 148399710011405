import { render, staticRenderFns } from "./WorkspacePricePreview.vue?vue&type=template&id=1fc1e6da&scoped=true&"
import script from "./WorkspacePricePreview.vue?vue&type=script&lang=ts&"
export * from "./WorkspacePricePreview.vue?vue&type=script&lang=ts&"
import style0 from "./WorkspacePricePreview.vue?vue&type=style&index=0&id=1fc1e6da&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fc1e6da",
  null
  
)

export default component.exports