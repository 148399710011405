
import Vue from 'vue';

export default Vue.extend({
	name: 'ConfirmDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: 400 },
		disabled: { type: Boolean, default: false },
		confirmLoading: { type: Boolean, default: false },
		hideCancel: { type: Boolean, default: false },
		hideConfirm: { type: Boolean, default: false },
		title: { type: String, default: null },
		bodyText: { type: String, default: null },
		cancelText: { type: String, default: 'Cancel' },
		confirmText: { type: String, default: 'Confirm' },
		showTopRightCloseBtn: { type: Boolean, default: false },
	},
	data () {
		return {
			open: false,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		onConfirm () {
			if (this.disabled) return;

			this.$emit('on-confirm', () => {
				this.setOpen(false)
			});
		},
	},
});
