
import Vue from 'vue';
import RunnitToolsGallery from '@/views/Runnits/RunnitToolsGallery.vue';

export default Vue.extend({
	name: 'RunnitAllToolsPage',
	props: {},
	data () {
		return {};
	},
	components: {
		RunnitToolsGallery,
	},
});
