import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.carouselUrls && _vm.carouselUrls.length)?_c('div',[_c(VDialog,{attrs:{"max-width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center",staticStyle:{"position":"relative"}},'div',attrs,false),on),[_c('img',{staticClass:"full-width",style:({
							opacity: hover ? '0.7' : '1',
							..._vm.imgStyle,
						}),attrs:{"src":_vm.carouselUrls[0]}}),(_vm.carouselUrls.length > 1)?_c(VIcon,{staticStyle:{"opacity":"0.8","cursor":"pointer","position":"absolute","top":"50%","right":"10px","transform":"translate(-50%, -50%)"},attrs:{"size":"35"}},[_vm._v(" mdi-chevron-right-circle ")]):_vm._e(),_c(VFadeTransition,[(hover)?_c(VChip,{staticStyle:{"opacity":"0.8","cursor":"pointer","position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"}},[_c('b',[_vm._v("View "+_vm._s(_vm._f("pluralize")('Image',_vm.carouselUrls.length)))])]):_vm._e()],1)],1)]}}],null,true)})]}}],null,false,1406852595),model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VSheet,{staticStyle:{"position":"relative"},attrs:{"color":"grey darken-3"}},[_c(VCarousel,{attrs:{"height":"100%","hide-delimiters":_vm.carouselUrls.length <= 1,"show-arrows":_vm.carouselUrls.length > 1}},_vm._l((_vm.carouselUrls),function(carouselUrl,i){return _c(VCarouselItem,{key:i},[_c('img',{staticClass:"full-width",staticStyle:{"min-height":"300px"},attrs:{"src":carouselUrl}})])}),1),_c(VSlideXReverseTransition,[(hover)?_c('base-button',{staticStyle:{"position":"absolute","top":"16px","right":"16px"},attrs:{"secondary":"","fab":"","small":""},on:{"click":function($event){_vm.dialogOpen = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1)],1)]}}],null,false,1104949035)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }