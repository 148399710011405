import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"workshop-card",class:{
			'workshop-card-hover': _vm.selectionMode && hover,
		},style:({
			border: _vm.selectionMode && _vm.selected ? '1px solid var(--primary-blue)' : '1px solid var(--grey-800)',
			minHeight: _vm.selectionMode ? '170px' : '270px',
		}),on:{"click":_vm.handleClick}},[_vm._t("content",function(){return [_c('div',{staticClass:"d-flex flex-column justify-space-between full-height full-width"},[(_vm.workshop)?_c('div',{staticClass:"full-width d-flex align-start justify-space-between"},[_c('div',{staticClass:"full-width d-flex flex-column full-height justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"workshop-card-name",class:{
									'gradient-text': _vm.selectionMode && _vm.selected,
								},staticStyle:{"max-width":"fit-content"}},[_vm._v(" "+_vm._s(_vm._f("truncateMiddle")(_vm.workshop.name,25))+" ")]),(!_vm.selectionMode && _vm.workshop.startAt)?_c('div',{staticStyle:{"position":"relative","color":"var(--grey-600)"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.workshop.startAt.toMillis(),'MMM D, YYYY'))+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex"},[((
								_vm.isAdmin &&
								!_vm.selectionMode
							))?_c(VBtn,{staticStyle:{"position":"relative","right":"-10px"},attrs:{"icon":"","fab":"","small":"","disabled":!_vm.isTeamActive},on:{"click":function($event){return _vm.$emit('on-edit-workshop', _vm.workshop)}}},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"}},[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(_vm.selectionMode && _vm.selected)?_c(VIcon,{staticStyle:{"color":"var(--primary-blue)"}},[_vm._v(" mdi-check-circle ")]):(_vm.selectionMode && !_vm.selected)?_c(VIcon,{attrs:{"color":"#575757"}},[_vm._v(" mdi-circle-outline ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"d-flex justify-center full-width"},[_c('base-button',{style:({ padding: '0 70px !important' }),on:{"click":_vm.openWorkshop}},[_vm._v(" Open ")])],1)])]})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }