
import Vue from 'vue';

export default Vue.extend({
	name: 'ComplexBackgroundSideNav',
	props: {
		includeLeftBlobs: { type: Boolean, default: false },
	},
	data () {
		return {};
	},
});
