
import Vue from 'vue';
import BaseSlider from '@/components/base/BaseSlider.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';

export default Vue.extend({
	name: 'StepsSlider',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: Number, default: null },
	},
	components: {
		BaseSlider,
		RunnitDynamicFieldLabelRow,
	},
});
