import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cfg-slider"},[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field,"label-styles":{
			padding: '0 0 4px 0',
		}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex flex-end"},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VSlideXReverseTransition,[(_vm.tooHighThreshold)?_c('div',_vm._g(_vm._b({staticClass:"too-high-threshold"},'div',attrs,false),on),[_c(VIcon,{staticStyle:{"color":"var(--amber-300)","font-size":"13px","margin":"0 4px 0 0"}},[_vm._v("mdi-alert-outline")]),_vm._v(" Warning ")],1):_vm._e()])]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" We recommend an artistic guidance of "),_c('b',[_vm._v("7")]),_vm._v(". But depending on certain use cases a higher/lower value could be desired. ")])])],1)]},proxy:true}])}),_c('base-slider',{attrs:{"value":_vm.value,"max":_vm.field.display.max || 20,"min":_vm.field.display.min || 1,"rules":_vm.field.required ? _vm.rules.requiredNumberField : _vm.rules.optionalNumberField,"hide-details":"","step":_vm.field.display.step || 1},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }