
import Vue from 'vue';
import { mapState } from 'vuex';
import _truncate from 'lodash/truncate';
import { TeamUserMixin } from '@/mixins';
import AddEditWorkspace from './AddEditWorkspace.vue';
import ErrorState from '@/components/states/ErrorState.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import WorkspaceCard from '@/views/Workspaces/WorkspaceCard.vue';
import InfoMessageSmall from '@/components/base/InfoMessageSmall.vue';
import { Workspace } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'Workspaces',
	mixins: [
		TeamUserMixin,
	],
	data () {
		return {
			MAX_WORKSPACES: 16,
			loading: false, // TODO: loading state with Vuex
			hasError: false, // TODO: error state with Vuex

			addEditWorkspaceConfig: {
				open: false,
				editMode: false,
				workspace: null,
			},

			localWorkSpaces: [],
		};
	},
	computed: {
		...mapState([
			'workspaces',
		]),
		maxWorkspacesReached () {
			return this.localWorkSpaces.length >= this.MAX_WORKSPACES;
		},
	},
	watch: {
		workspaces: {
			immediate: true,
			handler (newVal: Workspace[]) {
				this.localWorkSpaces = newVal || [];
			},
		},
	},
	methods: {
		_truncate,
		openAddWorkspaceDrawer () {
			this.addEditWorkspaceConfig.open = true;
			this.addEditWorkspaceConfig.editMode = false;
			this.addEditWorkspaceConfig.workspace = null;
		},
		onAddEditInput (open) {
			this.addEditWorkspaceConfig.open = open;
			this.addEditWorkspaceConfig.workspace = null;
		},
		onEditWorkspace (workspace) {
			this.addEditWorkspaceConfig.open = true;
			this.addEditWorkspaceConfig.editMode = true;
			this.addEditWorkspaceConfig.workspace = workspace;
		},
	},
	components: {
		InfoMessageSmall,
		WorkspaceCard,
		AddEditWorkspace,
		ErrorState,
		LoadingState,
	},
});
