import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rd-base-slider"},[_c('div',{staticClass:"base-slider-container"},[_c(VSlider,_vm._g(_vm._b({attrs:{"thumb-color":"white"}},'v-slider',_vm.$attrs,false),_vm.$listeners)),_c('number-with-incrementer',_vm._b({attrs:{"disabled":!!_vm.$attrs.disabled},on:{"input":_vm.onIncrement}},'number-with-incrementer',{
				value: _vm.$attrs.value,
				...(_vm.$attrs.step > 0 && {
					step: _vm.$attrs.step,
				}),
				...(!_vm._isNil(_vm.$attrs.min) && !_vm._isNil(_vm.$attrs.max) && {
					min: _vm.$attrs.min,
					max: _vm.$attrs.max,
				}),
			},false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }