import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('menu-or-bottom-sheet',{attrs:{"max-width":600,"min-width":250,"nudge-bottom":50,"open-on-hover":_vm.openOnHover,"close-delay":300,"close-on-click":_vm.closeOnClick,"bottom-sheet-persistent":_vm.bottomSheetPersistent},on:{"input":_vm.onOpenStateChange},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_vm._t("trigger",null,{"on":on})]}},{key:"content",fn:function({ close }){return [_c('div',{staticClass:"menu-item-container-outer",style:({
				...(_vm.$vuetify.breakpoint.xsOnly && {
					borderTopRightRadius: 'var(--global-border-radius)',
					borderTopLeftRadius: 'var(--global-border-radius)',
					paddingBottom: '25px',
					paddingTop: '5px',
				}),
			})},[_c('complex-background-base-styled-menu'),(_vm.menuItems.length)?_c('div',{staticClass:"menu-item-container",style:({
					width: _vm.menuWidth,
					...(_vm.horizontalMenu && !_vm.$vuetify.breakpoint.xsOnly && {
						'display': 'flex',
						'justify-content': 'space-around',
						'align-items': 'center',
						'gap': '8px',
					}),
				})},_vm._l((_vm.menuItems),function(menuItem,index){return _c(VBtn,{key:menuItem.id,staticClass:"menu-item",style:({
						marginBottom: index < _vm.menuItems.length - 1 ? '0' : '0',
					}),attrs:{"text":"","disabled":menuItem.disabled},on:{"click":function($event){return _vm.onMenuItemSelect(menuItem.value, close)}}},[_c('div',[_c(VIcon,{staticClass:"mr-3",class:{
								'gradient-text': _vm.value === menuItem.value,
							},staticStyle:{"font-size":"23px"},style:({
								color: (
									_vm.itemsAlwaysWhite ||
									_vm.value === menuItem.value
								)
									? 'white'
									: 'var(--grey-500)',
								...(menuItem.iconStyle)
							})},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('div',{style:({
							color: (
								_vm.itemsAlwaysWhite ||
								_vm.value === menuItem.value
							)
								? 'white'
								: 'var(--grey-500)',
						})},[_vm._v(" "+_vm._s(menuItem.label)+" ")])])}),1):_vm._e()],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }