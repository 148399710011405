
import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
	name: 'JoinCreatorsClub',
	props: {
		breakpointAtMd: { type: Boolean, default: false },
	},
	methods: {
		...mapActions([
			'updateUpsellDialog',
		]),
	},
});
