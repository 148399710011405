
import Vue from 'vue';
import { db } from '@/firebase';
import { mapState } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';
import _keys from 'lodash/keys';
import RunnitDynamicField from '@/views/Runnits/RunnitSettings/RunnitDynamicField.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import {
	RunnitNode,
	RunnitNodeField,
	RunnitNodeFieldGroup,
	RunnitNodeRun,
	RunnitNodeRunInputValue,
	RunnitNodeStaticFields,
	RunnitNodeStaticFieldsKey,
} from '@run-diffusion/shared';
import {
	RUNNIT_NODE_FIELDS_SOURCE,
	RUNNIT_NODE_RUN_MODE,
	RUNNIT_NODE_RUN_STATE,
	RUNNIT_NODE_STATIC_FIELDS_SOURCE,
} from '@/constants/enums';
import { mapFirebaseTimestampToDate } from '@/utils';

export default Vue.extend({
	name: 'AutoSaveRunnitNodeRunContainer',
	props: {
		node: { type: Object, required: true },
		inputValues: { type: Object, required: true },
		staticInputValues: { type: Object, required: true }, // These are controlled externally from this component
		incrementAutoSaveTrigger: { type: Number, default: 0 },
	},
	data () {
		return {
			debouncedDoSaveDraft: () => {},
			hasChangesToSave: false,
			isSavingChanges: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'draftRunnitNodeRun',
			'boundPropsDraftRunnitNodeRun',
			'loadingDraftRunnitNodeRun',
			'runnitState',
		]),
		shouldRenderFields () {
			return !(this.loadingDraftRunnitNodeRun || this.loadingDraftRunnitNodeRun === null);
		},
		isNodeRunQueuing () {
			return !!(
				this.draftRunnitNodeRun &&
				this.node.id === this.draftRunnitNodeRun.nodeId &&
				this.runnitState.isQueuingDraftRunnitNodeRun
			);
		},
	},
	watch: {
		node: {
			immediate: true,
			handler (newVal: RunnitNode, oldVal: RunnitNode) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (newId !== oldId) {
					this.keepDebounceFunctionInFlight();
				}
				if (newId && newId !== oldId) {
					this.emitNodeRunDefaults(this.draftRunnitNodeRun, newVal);
				}
			},
		},
		draftRunnitNodeRun: {
			immediate: true,
			handler (newVal: RunnitNodeRun, oldVal: RunnitNodeRun) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (newId !== oldId) {
					this.keepDebounceFunctionInFlight();
				}
				if (newId && newId !== oldId) {
					this.emitNodeRunDefaults(newVal, this.node);
				}
			},
		},
		incrementAutoSaveTrigger: {
			immediate: false,
			handler (newVal: number, oldVal: number) {
				if (newVal !== oldVal) {
					this.beginAutoSave(this.inputValues, this.staticInputValues);
				}
			},
		},
	},
	created () {
		this.initDebouncedDoSaveDraftMethod();
	},
	methods: {
		initDebouncedDoSaveDraftMethod () {
			this.debouncedDoSaveDraft = _debounce(this.doSaveDraft, 1000);
		},
		keepDebounceFunctionInFlight () {
			/*
			Resetting this to a fresh _debounce function every time the props to this component changes,
			will keep the last in-flight debounced function call active,
			and won't override it when called for this new RunnitNode.id
			 */
			this.initDebouncedDoSaveDraftMethod();
		},
		determineFields (node: RunnitNode) {
			return (
				this._get(node, 'fieldsSource') === RUNNIT_NODE_FIELDS_SOURCE.NODE
					? this._get(node, 'fields')
					: this._get(node, 'nodeDef.fields')
			) || [];
		},
		determineStaticFields (node: RunnitNode) {
			return (
				this._get(node, 'staticFieldsSource') === RUNNIT_NODE_STATIC_FIELDS_SOURCE.NODE
					? this._get(node, 'staticFields')
					: this._get(node, 'nodeDef.staticFields')
			) || {};
		},
		beginAutoSave (inputValues: Record<string, RunnitNodeRunInputValue>, staticInputValues: Record<RunnitNodeStaticFieldsKey, any>) {
			if (!this.shouldRenderFields) return;
			this.hasChangesToSave = true;
			this.debouncedDoSaveDraft(
				this.draftRunnitNodeRun,
				this.node,
				inputValues,
				staticInputValues,
			);
		},
		async doSaveDraft (
			draftRunnitNodeRun: RunnitNodeRun,
			node: RunnitNode,
			inputValues: Record<string, RunnitNodeRunInputValue>,
			staticInputValues: Record<RunnitNodeStaticFieldsKey, any>,
		) {
			this.hasChangesToSave = false;

			if (!node) return;
			if (
				this.isNodeRunQueuing &&
				draftRunnitNodeRun &&
				this.draftRunnitNodeRun &&
				draftRunnitNodeRun.id === this.draftRunnitNodeRun.id
			) return;
			if (_isEmpty(inputValues) && _isEmpty(staticInputValues)) return;
			if (
				draftRunnitNodeRun &&
				(
					draftRunnitNodeRun.state !== RUNNIT_NODE_RUN_STATE.DRAFT || // not draft state
					(
						_isEqual(draftRunnitNodeRun.inputs, inputValues) && // no diff
						_isEqual(draftRunnitNodeRun.staticInputs, staticInputValues) // no diff
					)
				)
			) return;

			try {
				this.isSavingChanges = true;

				// Value mappings
				const mappedInputValues: Record<string, RunnitNodeRunInputValue> = {};
				const mappedStaticInputValues: Record<RunnitNodeStaticFieldsKey, any> = {};
				_keys(inputValues).forEach((key: string) => {
					mappedInputValues[key] = mapFirebaseTimestampToDate(inputValues[key]);
				});
				_keys(staticInputValues).forEach((key: RunnitNodeStaticFieldsKey) => {
					mappedStaticInputValues[key] = mapFirebaseTimestampToDate(staticInputValues[key]);
				});

				if (draftRunnitNodeRun) {
					await db
						.collection(`runnitNodeRunDrafts`)
						.doc(draftRunnitNodeRun.id)
						.update({
							inputs: mappedInputValues,
							staticInputs: mappedStaticInputValues,
						});
				} else {
					const nowDate: Date = new Date();
					await db
						.collection(`runnitNodeRunDrafts`)
						.add({
							createdAt: nowDate,
							deletedAt: null,
							draftAt: nowDate,
							userId: this.user.id,
							runnitId: node.runnitId,
							nodeId: node.id,
							mode: RUNNIT_NODE_RUN_MODE.MANUAL,
							state: RUNNIT_NODE_RUN_STATE.DRAFT,
							inputs: mappedInputValues,
							staticInputs: mappedStaticInputValues,
						});
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.isSavingChanges = false;
			}
		},
		onFieldInput (field: RunnitNodeField, value: any) {
			if (!field || value === undefined) return;

			const changes: Record<string, RunnitNodeRunInputValue> = {
				...this.inputValues,
				[field.fieldDefUuid]: value,
			};
			this.emitInputValuesChanges(changes);
			this.beginAutoSave(changes, this.staticInputValues);
		},
		emitInputValuesChanges (changes: Record<string, RunnitNodeRunInputValue>) {
			if (_isEmpty(changes)) return;

			this.$emit('on-input-values-input', changes);
		},
		emitStaticInputValuesChanges (changes: Record<RunnitNodeStaticFieldsKey, any>) {
			if (_isEmpty(changes)) return;

			this.$emit('on-static-input-values-input', changes);
		},
		emitNodeRunDefaults (draftRunnitNodeRun: RunnitNodeRun, node: RunnitNode) {
			if (
				!node ||
				(draftRunnitNodeRun && draftRunnitNodeRun.nodeId !== node.id)
			) {
				return;
			}

			const determinedFields: (RunnitNodeFieldGroup | RunnitNodeField)[] = this.determineFields(node);
			const determinedStaticFields: RunnitNodeStaticFields = this.determineStaticFields(node);

			/*
			Default values from input fields
			 */
			const defaultInputValuesMap: Record<string, RunnitNodeRunInputValue> = {};
			const extractDefaultValuesFromNodeFields: Function = (fields: (RunnitNodeFieldGroup | RunnitNodeField)[]): void => {
				(fields || []).forEach((groupOrField: RunnitNodeFieldGroup | RunnitNodeField) => {
					const group: RunnitNodeFieldGroup = groupOrField as RunnitNodeFieldGroup;
					const field: RunnitNodeField = groupOrField as RunnitNodeField;
					if (group.__rgroup) {
						extractDefaultValuesFromNodeFields(group.fields);
					} else if (field.__rfield) {
						if (!_isNil(field.defaultValue)) {
							defaultInputValuesMap[field.fieldDefUuid] = field.defaultValue;
						}
					}
				});
			};
			extractDefaultValuesFromNodeFields(determinedFields);

			/*
			Default values from static input fields
			 */
			const defaultStaticInputValuesMap: Record<RunnitNodeStaticFieldsKey | string, any> = {};
			_keys(determinedStaticFields).forEach((key: RunnitNodeStaticFieldsKey) => {
				const staticField: any = determinedStaticFields[key];
				if (!_isNil(staticField.defaultValue)) {
					defaultStaticInputValuesMap[key] = staticField.defaultValue;
				}
			});

			this.emitInputValuesChanges({
				...defaultInputValuesMap,
				...(draftRunnitNodeRun && draftRunnitNodeRun.inputs),
			});
			this.emitStaticInputValuesChanges({
				...defaultStaticInputValuesMap,
				...(draftRunnitNodeRun && draftRunnitNodeRun.staticInputs),
			});
		},
	},
	components: {
		RunnitDynamicField,
		LoadingState,
	},
});
