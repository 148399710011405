
import Vue from 'vue';
import { ROUTER } from '@/router/constants';
import { mapActions, mapState } from 'vuex';
import { RunnitsCRUDMixin } from '@/mixins';
import { setRunnitsTeamAccessLevel } from '@/utils';
import { RUNNITS_ACCESS_LEVEL } from '@/constants/enums';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import GlassButton from '@/components/base/GlassButton.vue';
import RunnitTokens from '@/views/Runnits/RunnitTokens.vue';
import RunnitAccountSelectionDialog from '@/views/Runnits/RunnitAccountSelectionDialog.vue';
import ComplexBackgroundSideNav from '@/views/Runnits/RunnitSideNav/ComplexBackgroundSideNav.vue';
import WhiteTransparentChip from '@/components/WhiteTransparentChip.vue';

export default Vue.extend({
	name: 'RunnitSideNav',
	mixins: [
		RunnitsCRUDMixin,
	],
	props: {},
	data () {
		return {
			ROUTER,
			RUNNITS_OWNER_SELECTION,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		isSubscriptionsRoute () {
			return this.$route.name === ROUTER.RUNNITS_SUBSCRIPTIONS;
		},
		isHomeRoute () {
			return this.$route.name === ROUTER.RUNNITS_HOME;
		},
		// isLibraryRoute () {
		// 	return this.$route.name === ROUTER.RUNNITS_LIBRARY;
		// },
		isPrivateRoute () {
			return this.$route.name === ROUTER.RUNNITS_PRIVATE;
		},
		isSharedRoute () {
			return this.$route.name === ROUTER.RUNNITS_SHARED;
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateToolbar',
		]),
		goTo (routeName) {
			this.routerPush(this.$route, this.$router, { name: routeName });
		},
		async onPrivateClick () {
			setRunnitsTeamAccessLevel(RUNNITS_ACCESS_LEVEL.PRIVATE);
			await this.updateRunnitState({
				runnitsAccessLevel: RUNNITS_ACCESS_LEVEL.PRIVATE,
			});
			this.goTo(ROUTER.RUNNITS_PRIVATE);
		},
		async onSharedClick () {
			setRunnitsTeamAccessLevel(RUNNITS_ACCESS_LEVEL.SHARED);
			await this.updateRunnitState({
				runnitsAccessLevel: RUNNITS_ACCESS_LEVEL.SHARED,
			});
			this.goTo(ROUTER.RUNNITS_SHARED);
		},
		async onApexClick () {
			const runnitNodeDefWithId = {
				id: 'mzttaygaC3NXy6qOerIn',
				title: 'Runnit Apex',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId);
		},
		async onFooocusClick () {
			const runnitNodeDefWithId = {
				id: '0uBKkdW5Kgw2RXOwN1Af',
				title: 'Fooocus',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId);
		},
		async onCreativeUpscalerClick () {
			const runnitNodeDefWithId = {
				id: 'n4H8sQlLxTc1aeYJxdiv',
				title: 'Creative Upscaler',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId);
		},
		async onDalleClick () {
			const runnitNodeDefWithId = {
				id: 'Gp0T2FT48qKP76nPSiED',
				title: 'DALL-E',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId);
		},
		openRunDiffusionInNewTab () {
			const routeData = this.$router.resolve({
				name: 'Sessions',
			});
			window.open(routeData.href, '_blank');
		},
		closeSideNav () {
			this.updateToolbar({
				runnitsSideNavOpen: false,
			});
		},
	},
	components: {
		WhiteTransparentChip,
		GlassButton,
		RunnitTokens,
		ComplexBackgroundSideNav,
		RunnitAccountSelectionDialog,
	},
});
