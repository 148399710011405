import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VBtn,_vm._g(_vm._b({class:{
		..._vm.cssClass,
		'base-btn': _vm.isPrimary,
		'super-primary-btn': _vm.isSuperPrimary,
		'secondary-primary-btn': _vm.isSecondaryPrimary,
		'base-btn-disabled': _vm.isPrimary && _vm.disabled,
	},staticStyle:{"font-weight":"bold"},style:({
		...(!_vm.disabled && {
			color:
				_vm.isPrimary
					? 'white'
					: _vm.isSuperPrimary
						? 'black !important'
						: _vm.warning
							? '#dc2626 !important'
							: _vm.isTertiary
								? '#b0bec5 !important'
								: _vm.isLink
									? '#6f86ff !important'
									: _vm.callToAction
										? 'white !important'
										: 'inherit',
		}),
		...(_vm.isTertiary && {
			borderRadius: '12px !important',
		}),
	}),attrs:{"color":_vm.btnColor,"rounded":_vm.rounded,"loading":_vm.loading,"disabled":_vm.disabled,"outlined":_vm.isSecondary && !_vm.disabled,"text":_vm.text || _vm.warning,"elevation":_vm.elevation}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }