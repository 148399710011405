import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":_vm.computedMaxWidth,"persistent":_vm.persistent,"overlay-opacity":"0.94","content-class":"runnit-image-info-carousel-dialog"},on:{"input":_vm.setOpen},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_vm._t("activator",null,null,props)]}}],null,true)},[_c('div',{ref:"runnitImageInfoCarouselDialog",staticClass:"image-info"},[((
				_vm.$vuetify.breakpoint.mdAndUp &&
				_vm.currNodeRunResultIndex > 0
			))?_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"arrow-button arrow-button-left no-select",style:({
					backgroundImage: `radial-gradient(circle at 30% 20%, ${hover ? 'var(--grey-400)' : 'var(--grey-550)'}, ${hover ? 'var(--grey-700)' : 'var(--grey-825)'} 55%)`,
					...(_vm.$vuetify.breakpoint.mdOnly && {
						left: 'calc(9% - 80px)', /* Half of the container width (900px/2) minus half of the column width (700px/2) minus the desired spacing (40px) */
					}),
					...(_vm.$vuetify.breakpoint.lgAndUp && {
						left: 'calc(10% - 130px)', /* Half of the container width (900px/2) minus half of the column width (700px/2) minus the desired spacing (40px) */
					}),
				}),on:{"click":_vm.onPrevClick}},[_vm._v("   ")])]}}],null,false,4074993956)}):_vm._e(),((
				_vm.$vuetify.breakpoint.mdAndUp &&
				_vm.currNodeRunResultIndex < (_vm.resultsWithNodeRun.length - 1)
			))?_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"arrow-button arrow-button-right no-select",style:({
					backgroundImage: `radial-gradient(circle at 30% 20%, ${hover ? 'var(--grey-400)' : 'var(--grey-550)'}, ${hover ? 'var(--grey-700)' : 'var(--grey-825)'} 55%)`,
					...(_vm.$vuetify.breakpoint.mdOnly && {
						right: 'calc(9% - 80px)', /* Half of the container width (900px/2) minus half of the column width (700px/2) minus the desired spacing (40px) */
					}),
					...(_vm.$vuetify.breakpoint.lgAndUp && {
						right: 'calc(10% - 130px)', /* Half of the container width (900px/2) minus half of the column width (700px/2) minus the desired spacing (40px) */
					}),
				}),on:{"click":_vm.onNextClick}},[_vm._v("   ")])]}}],null,false,2048947113)}):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"image-info-image-section",style:({
						...(_vm.$vuetify.breakpoint.smAndUp && {
							margin: '0 20px 0 0',
						}),
					})},[_c('runnit-image',{attrs:{"node-run-result":_vm.nodeRunResult,"is-thumb":_vm.$vuetify.breakpoint.xsOnly,"is-inside-info-carousel":false,"use-zoom-viewer":""}})],1)]),_c(VCol,{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"image-info-info-section"},[_c('image-info',{attrs:{"node-run":_vm.nodeRun,"inside-drawer":"","inside-carousel":"","ignore-truncation":""}})],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }