import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"1000","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_vm._t("activator",null,null,props)]}}],null,true),model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c(VCard,{staticStyle:{"height":"90vh"}},[_c(VCardTitle,{class:{
				'pt-8 px-8': _vm.$vuetify.breakpoint.xsOnly,
				'pt-12 px-12': !_vm.$vuetify.breakpoint.xsOnly,
			}},[_c('span',{staticClass:"dialog-title",style:({
					'font-size': _vm.$vuetify.breakpoint.xsOnly ? '18px' : '24px',
				})},[_vm._v(" Extend Session Timer ")])]),_c(VCardText,{style:({
				height: _vm.$vuetify.breakpoint.xsOnly ? '50vh' : '65vh',
			})},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",class:{
					'px-0': _vm.$vuetify.breakpoint.xsOnly,
					'px-12': !_vm.$vuetify.breakpoint.xsOnly,
				}},[_c('div',{staticClass:"full-width d-flex justify-center mt-7"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('extend-offer-price-card',{class:{
									'mr-2': !_vm.$vuetify.breakpoint.xsOnly,
								},attrs:{"count-down-timer-val":_vm.countDownTimerVal,"session":_vm.session}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('balance-card',{staticClass:"full-width full-height",class:{
									'ml-2': !_vm.$vuetify.breakpoint.xsOnly,
								}})],1)],1)],1),_c(VSheet,{staticClass:"full-width mt-6",class:{
						'pa-5': _vm.$vuetify.breakpoint.xsOnly,
						'pa-8': !_vm.$vuetify.breakpoint.xsOnly,
					},staticStyle:{"margin":"0 0 80px 0"},attrs:{"outlined":""}},[_c('time-your-session-inputs',{attrs:{"selectedStorageType":_vm.session.teamId ? _vm.SELECTED_STORAGE_TYPE.TEAM : _vm.SELECTED_STORAGE_TYPE.PRIVATE,"insufficient-balance-message":"You do not have enough balance to extend a session. Please add funds to your balance above.","title":"How much time should we add to your session timer?","disabled":_vm.extending,"purchase-price":_vm.session.purchasePrice,"extending-from-scheduled-stop-at":_vm.session.scheduledStopAt,"selected-scheduled-stop-in-hrs":_vm.selectedScheduledStopInHrs,"selected-scheduled-stop-in-mins":_vm.selectedScheduledStopInMins},on:{"selected-scheduled-stop-in-hrs-input":function($event){_vm.selectedScheduledStopInHrs = $event},"selected-scheduled-stop-in-mins-input":function($event){_vm.selectedScheduledStopInMins = $event}}}),_c('session-sounds-settings',{staticClass:"mt-9"}),_c('how-to-stop-early-notice',{staticClass:"mt-10"})],1),_c('actions-island',{style:({
						position: 'absolute',
					}),attrs:{"width":_vm.$vuetify.breakpoint.xsOnly ? '80%' : '98%',"height":"70px","bottom":_vm.$vuetify.breakpoint.xsOnly ? '15px' : '9px'}},[_c('div',{staticClass:"full-width full-height d-flex justify-center align-center"},[_c('base-button',{staticClass:"mr-4",attrs:{"disabled":_vm.extending,"secondary":""},on:{"click":_vm.onCancel}},[_vm._v(" Close ")]),_c('base-button',{staticStyle:{"min-width":"120px"},attrs:{"disabled":_vm.confirmDisabled,"loading":_vm.extending,"super-primary":""},on:{"click":_vm.onConfirm}},[_vm._v(" Confirm ")])],1)])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }