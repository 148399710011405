import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnits-page-container"},[_c('div',{staticClass:"runnits-page-background"}),_c(VAppBar,_vm._b({staticClass:"runnits-vuetify-app-bar",staticStyle:{"padding":"0"},style:({
			borderBottom: '1px solid var(--grey-850)',
		}),attrs:{"app":"","height":"60","fixed":"","clipped-right":"","clipped-left":""}},'v-app-bar',{
			...(_vm.isAdminProxyingUser && {
				color: 'rgb(95, 6, 6)',
			}),
		},false),[_c('div',{staticClass:"runnits-app-bar",style:({
				...(_vm.$vuetify.breakpoint.mdAndUp && {
					justifyContent: 'center',
				}),
				...(_vm.$vuetify.breakpoint.smAndDown && {
					justifyContent: 'flex-end',
				}),
			})},[_c('div',{staticClass:"runnits-app-bar-top-left-section"},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',_vm._g(_vm._b({staticClass:"runnits-app-bar-logo-section",style:({
									background: hover ? 'var(--dark-purple-700)' : 'var(--dark-purple-900)',
									padding: _vm.$vuetify.breakpoint.mdAndUp ? '0 15px 0 0' : '0 -20px 0 0',
								}),on:{"click":_vm.onBackClick}},'div',attrs,false),on),[_c('complex-background-header',{attrs:{"include-left-blobs":""}}),_c(VIcon,{staticStyle:{"transform":"rotate(180deg)"},style:({
										color: hover ? 'var(--grey-100)' : 'var(--grey-500)',
										...(_vm.$vuetify.breakpoint.xsOnly && {
											margin: '0 10px 0 7px',
										}),
										...(_vm.$vuetify.breakpoint.smAndUp && {
											margin: '0 10px 0 10px',
										}),
									})},[_vm._v(" mdi-exit-to-app ")]),_c('div',{staticStyle:{"height":"60px"}},[(_vm.$vuetify.breakpoint.mdAndUp)?[_c('div',{staticClass:"temp-runnits-logo",staticStyle:{"margin":"10px 0 6px 0","padding":"7px 12px 7px 12px","position":"relative"}},[_c('div',{staticClass:"temp-runnits-logo-title",staticStyle:{"font-size":"18px"}},[_vm._v(" Runnits ")]),_c('white-transparent-chip',{style:({
													margin: '0 0 0 10px',
												}),attrs:{"padding":"3px 6px 4px 6px","font-size":"11px","width":"44px","height":"23px"}},[_vm._v(" beta ")])],1)]:[_c('img',{staticStyle:{"position":"relative","margin":"0 15px 0 0"},style:({
												top: _vm.$vuetify.breakpoint.xsOnly ? '14px' : '10px',
											}),attrs:{"alt":"Logo","height":_vm.$vuetify.breakpoint.xsOnly ? '35px' : '40px',"src":require("../../assets/rd-logo-white-only-cropped.png")}})]],2)],1)]}}],null,true)})]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Go back to Runnits home")])]),(_vm.runnitState.runnitDraft || (_vm.runnitState.runnit && (!_vm.runnitState.runnit.avatar || typeof _vm.runnitState.runnit.avatar !== 'string')))?_c('runnit-settings'):_vm._e()],1),_c('div',{staticClass:"runnit-page-settings-container",style:({
					...(_vm.$vuetify.breakpoint.smOnly && {
						right: '25%',
					}),
					...(_vm.$vuetify.breakpoint.mdOnly && {
						right: '-50px',
					}),
					...(_vm.$vuetify.breakpoint.xsOnly && {
						right: '115px',
					}),
					...(_vm.$vuetify.breakpoint.lgAndUp && {
						right: '-10%',
					}),
				})},[_c('runnit-page-settings',{attrs:{"icon-only":_vm.$vuetify.breakpoint.xsOnly,"icon-color":_vm.$vuetify.breakpoint.xsOnly ? 'var(--blue-grey-200)' : 'rgba(255, 255, 255, 0.7)',"hide-image-mode":_vm.$vuetify.breakpoint.xsOnly,"hide-selected-columns":_vm.$vuetify.breakpoint.xsOnly}})],1),_c('div',{staticClass:"runnits-app-bar-account-section"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('runnit-account-selection-dialog',{style:({
						margin: _vm.$vuetify.breakpoint.xsOnly ? '0 57px 0 0' : '0 15px 0 0',
					})}):_vm._e(),_c('get-support-btn',{attrs:{"inside-runnits":""}}),_c('account-menu-btn',{key:"account-menu-btn"})],1)])]),_c(VSlideYTransition,[(_vm.isRunnitBulkActionMenuOpen)?_c(VCol,{staticClass:"px-3 py-0",staticStyle:{"position":"absolute"},attrs:{"cols":"12","md":"10","offset-md":"1","lg":"10","offset-lg":"1"}},[_c(VAppBar,{staticClass:"runnits-vuetify-app-bar",staticStyle:{"position":"relative","border-bottom":"1px solid var(--grey-850)","background":"var(--grey-950)","background-image":"radial-gradient(circle at -5% -90%, var(--grey-875), var(--grey-950) 75%)","box-shadow":"var(--purple-header-top-shadow)","border-bottom-left-radius":"var(--global-border-radius)","border-bottom-right-radius":"var(--global-border-radius)"},style:({
					...(_vm.$vuetify.breakpoint.xsOnly && {
						top: '60px',
					}),
				}),attrs:{"fixed":"","height":"61"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"flex-end","gap":"10px","margin":"0 20px"}},[_c('div',{staticClass:"mr-2",staticStyle:{"color":"var(--grey-550)","font-size":"13px"}},[_vm._v(" ( "),_c('span',{style:({
								color: _vm.selectedImagesCount > 0 ? 'white' : 'var(--grey-550)',
								fontWeight: _vm.selectedImagesCount > 0 ? 'bold' : 'normal',
							})},[_vm._v(_vm._s(_vm.selectedImagesCount))]),_vm._v(" selected ) ")]),_c('grey-button',{attrs:{"disabled":!_vm.selectedImagesCount || _vm.deletingBulkImages},on:{"click":_vm.handleDownloadSelectedImages}},[_c(VIcon,{staticStyle:{"font-size":"19px"},style:({ color: (!_vm.selectedImagesCount || _vm.deletingBulkImages) ? 'var(--grey-600)' : 'var(--grey-400)' }),attrs:{"left":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("mdi-tray-arrow-down")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{style:({
								color: (!_vm.selectedImagesCount || _vm.deletingBulkImages) ? 'var(--grey-600)' : 'var(--grey-400)',
							})},[_vm._v("Download")]):_vm._e()],1),_c('grey-button',{attrs:{"disabled":!_vm.selectedImagesCount || _vm.deletingBulkImages},on:{"click":function($event){return _vm.setDeleteImagesDialog(true)}}},[_c(VIcon,{staticStyle:{"font-size":"19px"},style:({ color: (!_vm.selectedImagesCount || _vm.deletingBulkImages) ? 'var(--grey-600)' : 'var(--grey-400)' }),attrs:{"left":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("mdi-trash-can")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{style:({
								color: (!_vm.selectedImagesCount || _vm.deletingBulkImages) ? 'var(--grey-600)' : 'var(--grey-400)',
							})},[_vm._v("Delete")]):_vm._e()],1),_c('base-button',{attrs:{"secondary":""},on:{"click":function($event){return _vm.setBulkActionMenuOpen(false)}}},[_c(VIcon,{staticStyle:{"font-size":"19px","color":"var(--grey-400)"},attrs:{"left":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("mdi-close")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticStyle:{"color":"var(--grey-400)"}},[_vm._v("Exit mode")]):_vm._e()],1)],1)])],1):_vm._e()],1),_c(VRow,{ref:"runnitRowScrollContainer",staticClass:"runnit-row",style:({
			...(_vm.$vuetify.breakpoint.xsOnly && !!_vm.runnitState.selectedNodeId && {'overflow-y': 'hidden'}),
			...(_vm.isRunnitBulkActionMenuOpen && {'padding-top': '60px'}),
		})},[_c(VCol,{staticStyle:{"height":"100vh"},attrs:{"cols":"12","md":"10","offset-md":"1","lg":"10","offset-lg":"1"}},[_c('div',{staticClass:"d-flex",staticStyle:{"margin":"20px 0 100px 0","padding":"0 0 200px 0"}},[_c('router-view')],1)])],1),_c('image-gallery-drawer',{attrs:{"el-id-suffix":"runnits-master","value":_vm.runnitState.imageGalleryDrawerOpen},on:{"input":_vm.onGalleryDrawerClose}}),_c('double-confirm-danger-dialog',{ref:"doubleConfirmDangerDialog",attrs:{"value":_vm.bulkDeleteConfirmDialogOpen,"action-text":`Delete ${_vm.pluralize('Image', _vm.selectedImagesCount)}`,"action-disabled":_vm.deletingBulkImages,"title":`Delete ${_vm.pluralize('Image', _vm.selectedImagesCount)}`,"confirm-loading":_vm.deletingBulkImages,"disabled":_vm.deletingBulkImages},on:{"on-confirm":_vm.handleDeleteSelectedImages,"on-cancel":function($event){return _vm.setDeleteImagesDialog(false)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex flex-column my-4"},[_c('div',{staticClass:"d-flex justify-center full-width"},[_c('div',{staticClass:"mt-6 mb-7 d-flex align-center justify-center",staticStyle:{"padding":"15px","background":"var(--red-600-background)","border-radius":"50%","width":"70px","height":"70px"}},[_c(VIcon,{staticStyle:{"font-size":"45px","color":"var(--red-600)"}},[_vm._v("mdi-alert-circle-outline")])],1)]),_c('div',{staticClass:"mb-10 text-center",staticStyle:{"font-size":"18px","line-height":"28px"}},[_c('p',[_vm._v("Are you sure you want to delete the "),_c('span',{staticStyle:{"color":"var(--red-600)","font-weight":"bold"}},[_vm._v(_vm._s(_vm.selectedImagesCount))]),_vm._v(" "+_vm._s(_vm._f("pluralize")('Image',_vm.selectedImagesCount))+"?")])])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }