
import Vue from 'vue';
import _get from 'lodash/get';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import ComplexBackgroundBaseStyledMenu from '@/components/designElements/ComplexBackgroundBaseStyledMenu.vue';

export default Vue.extend({
	name: 'RunnitSingleSelect',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [Number, String, Object, Array], default: null },
	},
	data () {
		return {
			tempItems: [
				{ id: 1, label: 'Item 1', value: 'item1', image: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fusers%2Fei7OE9EsV6d5OTfkG60JeEa4zl22%2Fruns%2FUyTy1IAzPetdBzqLB3xt%2Fthumbs%2F0g3uT5_cxgFvzZ2wngtRs_512x512.webp?alt=media&token=ac468395-d4a0-4192-b8d9-de64973f9121' },
				{ id: 2, label: 'Item 2', value: 'item2', image: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fusers%2Fei7OE9EsV6d5OTfkG60JeEa4zl22%2Fruns%2FaJ8nkL3sJLh3xQnv8Lxm%2Fthumbs%2FaNC5oQrX6KBtkOP2WmIvd_512x512.webp?alt=media&token=1bc940d0-9f0b-4677-936a-cbdf5879f198' },
				{ id: 3, label: 'Item 3', value: 'item3', image: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fusers%2Fei7OE9EsV6d5OTfkG60JeEa4zl22%2Fruns%2FzHppH7piTOZRVtsBULg3%2Fthumbs%2FR9TYm-DeX26LLVj7mxJfw_512x512.webp?alt=media&token=6cc06299-1f02-4f2b-8716-ccbc51269af9' },
			],
		};
	},
	computed: {
		localValue () {
			let value = this.value ? this.tempItems.find((item) => item.id === this.value) : null

			if (_get(this.field, 'display.images') && !value) {
				value = this.tempItems[0];
			}

			return value;
		},
	},
	methods: {
		onSelectImageOption (option: any, close: () => void) {
			this.$emit('input', option.id);

			if (close) {
				close();
			}
		},
		emitInput (value: string) {
			this.$emit('input', value);
		},
	},
	components: {
		ComplexBackgroundBaseStyledMenu,
		MenuOrBottomSheet,
		RunnitDynamicFieldLabelRow,
	},
});
