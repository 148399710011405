
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitsSubscriptionsPage',
	props: {},
	data () {
		return {
			// TODO replace this temp dummy data with actual data
			currentClub: 'RUNFREE',
			clubs: [
				{
					key: 'RUNFREE',
					title: 'Free',
					price: 0,
					addOns: [
						{
							id: 'free-token',
							type: 'Tokens',
							description: '10 / day',
						},
						{
							id: 'free-runnit',
							type: 'Runnits',
							description: '1'
						}
					]
				},
				{
					key: 'RUNHOB',
					title: 'Hobby',
					price: 1199,
					addOns: [
						{
							id: 'hob-token',
							type: 'Tokens',
							description: '1000 / month',
						},
						{
							id: 'hob-runnit',
							type: 'Runnits',
							description: '5'
						}
					]
				},
				{
					key: 'RUNPRO',
					title: 'Pro',
					price: 2999,
					addOns: [
						{
							id: 'pro-token',
							type: 'Tokens',
							description: '5000 / month',
						},
						{
							id: 'pro-runnit',
							type: 'Runnits',
							description: '10'
						}
					]
				},
			]
		};
	},
	computed: {
	},
	watch: {
		
	},
	methods: {
		// TODO it'll probably be better to adjust the real data to inject a dollar amount and a cents amount rather than calling these functions in the html.
		getDollars (amount) {
			return +`${amount}`.slice(0, -2);
		},
		getCents (amount) {
			return +`${amount}`.slice(-2);
		},
		switchPlan (club) {
			console.log('Implement switchPlan', {club});
			this.currentClub = club.key;
		},
	},
	components: {
	},
});
