
import Vue from 'vue';
import _truncate from 'lodash/truncate';
import { RUNNIT_NODE_FIELD_TYPE, RUNNIT_NODE_STATIC_FIELDS_KEY } from '@/constants/enums';
import { SETTING_ACTIONS } from '@/views/Runnits/constants';
import RunnitModelSelection from '@/views/Runnits/RunnitSettings/RunnitModelSelection.vue';

export default Vue.extend({
	name: 'ImageInfo',
	props: {
		nodeRun: { type: Object, required: true },
		insideDrawer: { type: Boolean, default: false },
		insideNode: { type: Boolean, default: false },
		insideCarousel: { type: Boolean, default: false },
		ignoreTruncation: { type: Boolean, default: false },
		index: { type: [String, Number], default: null },
	},
	data () {
		return {
			SETTING_ACTIONS,
			RUNNIT_NODE_FIELD_TYPE,
		};
	},
	computed: {
		numImgs () {
			return this._get(this.nodeRun, 'results.length') || this._get(this.nodeRun, `staticInputs[${RUNNIT_NODE_STATIC_FIELDS_KEY.numResults}]`) || 1;
		},
		isFirstIndex () {
			return +this.index === 0;
		},
		fieldValueTruncateCharCount () {
			let charCount: number = 400;
			if (this.$vuetify.breakpoint.xsOnly || this.insideDrawer) {
				charCount = 100;
			}
			if (this.ignoreTruncation) {
				charCount = 3000;
			}
			return charCount;
		},
		firstLetterOfEmail () {
			return this.nodeRun.email ? this.nodeRun.email[0].toUpperCase() : '';
		},
	},
	methods: {
		_truncate,
		determineWidth () {
			let width = '100%';
			if (
				this.$vuetify.breakpoint.smAndUp &&
				!this.insideDrawer
			) {
				width = '50%';
			}
			return width;
		},
	},
	components: {
		RunnitModelSelection,
	},
});
