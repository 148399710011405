
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';
import { mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import { CLUB_KEY } from '@/constants/enums';
import { SELECTED_STORAGE_TYPE, UPSELL_TYPE } from '@/constants/constants';
import TeamPicker from '@/components/TeamPicker.vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';
import BorderCardUpsell from '@/components/BorderCardUpsell.vue';
import StorageTypeCard from '@/views/Sessions/StorageTypesList/StorageTypeCard.vue';

export default Vue.extend({
	name: 'StorageTypesList',
	props: {
		selectedStorageType: {
			type: String,
			validator: (val: string) => [SELECTED_STORAGE_TYPE.PRIVATE, SELECTED_STORAGE_TYPE.TEAM].includes(val),
			required: true,
		},
	},
	data () {
		return {
			UPSELL_TYPE,
			SELECTED_STORAGE_TYPE,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
		]),
		items () {
			return [
				{
					value: SELECTED_STORAGE_TYPE.PRIVATE,
					label: 'Personal Session',
					icon: 'mdi-account-outline',
					descriptionHtml: `<div>This will use both your personal <b>storage</b> and <b>balance</b> ${this.user.club === CLUB_KEY.CC_001 ? `<span>from your <span class="gradient-text font-weight-bold">Creator's Club</span> membership</span>` : ''}</div>`,
				},
				{
					value: SELECTED_STORAGE_TYPE.TEAM,
					label: 'Team Session',
					icon: 'mdi-account-multiple-outline',
					descriptionHtml: `<div>This will use both your team <b>storage</b> and <b>balance</b> that is shared between everyone in the team</div>`,
				},
			];
		},
	},
	methods: {
		_isEmpty,
		doUpsellToTeamPlan () {
			window.open(this.$router.resolve({ name: ROUTER.TEAM_MEMBERS }).href, '_blank');
		},
	},
	components: {
		BorderCardUpsell,
		BorderSelectCard,
		TeamPicker,
		StorageTypeCard,
	},
});
