import { render, staticRenderFns } from "./ImageGenerationsReport.vue?vue&type=template&id=4941e13a&scoped=true&"
import script from "./ImageGenerationsReport.vue?vue&type=script&lang=ts&"
export * from "./ImageGenerationsReport.vue?vue&type=script&lang=ts&"
import style0 from "./ImageGenerationsReport.vue?vue&type=style&index=0&id=4941e13a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4941e13a",
  null
  
)

export default component.exports