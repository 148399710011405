
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Tokens SVG',
		},
		width: {
			type: [Number, String],
			default: 15,
		},
		height: {
			type: [Number, String],
			default: 15,
		},
		viewBox: {
			type: String,
			default: '0 0 24 24',
		},
		fill: {
			type: String,
			default: null,
		},
	},
	computed: {
		gradientId (): string {
			return `gradient-${this._uid}`;
		},
		strokeColor (): string {
			return this.fill ? this.fill : `url(#${this.gradientId})`;
		},
	},
});
