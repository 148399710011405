
import Vue from 'vue';
import _get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import { RunnitNodeRun, RunnitNodeRunResult } from '@run-diffusion/shared';
import { SELECTED_IMAGE_MODE } from '@/views/Runnits/constants';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import ImageInfo from '@/components/ImageGallery/ImageInfo.vue';

interface RunnitNodeRunResultWithNodeRun {
	nodeRun: RunnitNodeRun;
	nodeRunResult: RunnitNodeRunResult;
}

export default Vue.extend({
	name: 'RunnitImageInfoCarouselDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		nodeRun: { type: Object, default: null },
		nodeRuns: { type: Array, default: null },
		nodeRunResult: { type: Object, default: null },
	},
	data () {
		return {
			// Observed dimensions
			dimensions: {
				width: 0,
				height: 0,
			},
			vuetify: this.$vuetify,
			touchStartX: 0,
			touchEndX: 0,
		};
	},
	created () {
		// Add event listeners for keydown when the component is created
		document.addEventListener('keydown', this.handleKeyDown);
		// Add event listeners for touch events
		document.addEventListener('touchstart', this.handleTouchStart);
		document.addEventListener('touchend', this.handleTouchEnd);
	},
	destroyed () {
		// Remove event listeners for keydown when the component is destroyed
		document.removeEventListener('keydown', this.handleKeyDown);
		// Remove event listeners for touch events
		document.removeEventListener('touchstart', this.handleTouchStart);
		document.removeEventListener('touchend', this.handleTouchEnd);
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		computedMaxWidth () {
			let maxWidth: string = '90%';

			if (this.$vuetify.breakpoint.mdAndUp) {
				maxWidth = '85%';
			}

			if (this.$vuetify.breakpoint.width >= 2130) {
				maxWidth = '1810px';
			}

			return maxWidth;
		},
		resultsWithNodeRun () {
			let results: RunnitNodeRunResultWithNodeRun[] = [];
			if (this.runnitState.selectedImageMode === SELECTED_IMAGE_MODE.TILED) {
				(this.nodeRuns || [])
					.forEach((nodeRun) => {
						(nodeRun.results || []).forEach((result) => {
							results.push({
								nodeRun,
								nodeRunResult: result,
							});
						})
					});
			} else if (this.runnitState.selectedImageMode === SELECTED_IMAGE_MODE.INFO) {
				(_get(this.nodeRun, 'results') || []).forEach((result) => {
					results.push({
						nodeRun: this.nodeRun,
						nodeRunResult: result,
					});
				});
			}

			return results;
		},
		currNodeRunResultIndex () {
			if (!this.resultsWithNodeRun.length) return null;
			let foundIndex: number = null;
			this.resultsWithNodeRun.some(({ nodeRunResult }, index: number) => {
				if (nodeRunResult.uuid === _get(this.nodeRunResult, 'uuid')) {
					foundIndex = index;
					return true;
				}
				return false;
			});
			return foundIndex;
		},
	},
	methods: {
		...mapActions([
			'incrementImageZoomResetTrigger',
		]),
		setOpen (val: boolean) {
			if (this.value !== val) {
				this.$emit('input', !!val);
			}

			if (!val) {
				this.incrementImageZoomResetTrigger();
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		onSelectionChosen () {
			this.onCancel();
		},
		onPrevClick () {
			if (this.currNodeRunResultIndex <= 0) return;

			this.$emit('on-prev-or-next-result', this.resultsWithNodeRun[this.currNodeRunResultIndex - 1]);
		},
		onNextClick () {
			if (this.currNodeRunResultIndex >= (this.resultsWithNodeRun.length - 1)) return;

			this.$emit('on-prev-or-next-result', this.resultsWithNodeRun[this.currNodeRunResultIndex + 1]);
		},
		handleKeyDown (event) {
			switch (event.key) {
				case 'ArrowLeft':
					this.handleLeftArrow();
					break;
				case 'ArrowRight':
					this.handleRightArrow();
					break;
			}
		},
		handleLeftArrow () {
			this.onPrevClick();
		},
		handleRightArrow () {
			this.onNextClick();
		},
		handleTouchStart (event: TouchEvent) {
			this.touchStartX = event.changedTouches[0].screenX;
		},
		handleTouchEnd (event: TouchEvent) {
			this.touchEndX = event.changedTouches[0].screenX;
			this.handleSwipe();
		},
		handleSwipe () {
			const swipeThreshold = 50; // Minimum distance (in pixels) to trigger a swipe
			const swipeDistance = this.touchEndX - this.touchStartX;

			if (Math.abs(swipeDistance) > swipeThreshold) {
				if (swipeDistance > 0) {
					this.handleRightSwipe();
				} else {
					this.handleLeftSwipe();
				}
			}
		},
		handleLeftSwipe () {
			this.onNextClick();
		},
		handleRightSwipe () {
			this.onPrevClick();
		},
	},
	components: {
		ImageInfo,
		RunnitImage,
	},
});
