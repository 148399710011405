
import Vue from 'vue';
import OfferingCard from '@/components/OfferingCard.vue';
import ComplexBackgroundBig from '@/components/designElements/ComplexBackgroundBig.vue';
import { ROUTER } from '@/router/constants';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import WhiteTransparentChip from '@/components/WhiteTransparentChip.vue';

export default Vue.extend({
	name: 'RunnitsPromoCard',
	props: {
		showButton: { type: Boolean, default: false },
		showAnimation: { type: Boolean, default: false },
	},
	data () {
		return {
			runnits: [
				{
					id: 2,
					title: 'Sketch to Render',
					src: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Frd-webapp%2Frunnit-node-hero-images%2Fsketch-to-render.png?alt=media&token=c538d9aa-af38-4136-b6d8-8832a9677877',
					description: 'Take a sketch and create a render from it',
					appType: RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				},
				{
					id: 3,
					title: 'Juggernaut XI',
					src: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Frd-webapp%2Frunnit-node-hero-images%2Fjuggernaut.png?alt=media&token=10134902-76f0-41e9-9fab-1be70b0de44b',
					description: ` Latest juggernaut release with our curated pipeline behind it `,
					appType: RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				},
				{
					id: 1,
					title: 'Foooocus',
					src: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fapp-offers%2Ffooocus_graphic_widescreen.webp?alt=media&token=7134acaf-80b9-45d5-8d36-1ddd9b74aa87',
					description: 'Use the Fooocus pipeline that you know and love',
					appType: RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
				},
			],
			numGridItems: 16,
		};
	},
	computed: {
		computedTransform () {
			let rotateX = 53;
			let rotateY = 356;
			let rotateZ = 31;
			let scale = 0.9;
			let translateY = -220;
			let translateX = 50;

			if (this.$vuetify.breakpoint.xsOnly) {
				rotateZ = 44;
				scale = 0.35;
				translateY = -1570;
				translateX = 438;
			} else if (this.$vuetify.breakpoint.smOnly) {
				rotateZ = 40;
				scale = 0.45;
				translateY = -970;
				translateX = 260;
			} else if (this.$vuetify.breakpoint.mdOnly) {
				rotateZ = 41;
				scale = 0.65;
				translateY = -468;
				translateX = 150;
			} else if (this.$vuetify.breakpoint.xlOnly) {
				rotateZ = 21;
				scale = 1.1;
				translateY = -120;
				translateX = -100;
			}

			return `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg) scale(${scale}) translateY(${translateY}px) translateX(${translateX}px)`;
		},
	},
	methods: {
		goToRunnitsHome () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_HOME });
		},
	},
	components: {
		WhiteTransparentChip,
		OfferingCard,
		ComplexBackgroundBig,
	},
});
