
import Vue from 'vue';
import _isArray from 'lodash/isArray';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import BaseSlider from '@/components/base/BaseSlider.vue';
import { RunnitNodeField } from '@run-diffusion/shared';
import _mapKeys from 'lodash/mapKeys';

export default Vue.extend({
	name: 'WidthHeightField',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [String, Array], default: null },
	},
	data () {
		return {
			MIN_DIMENSION: 8,
			DIVISIBLE_BY: 8,
			optionsMap: {},
		};
	},
	computed: {
		isSelectDisplayWithValueFormat () {
			return this._get(this.field, 'display.select.format') === 'value';
		},
		computedSelectFieldValue () {
			if (this.isSelectDisplayWithValueFormat) {
				return this.value;
			}
			if (!_isArray(this.value) || this.value.length < 2) {
				return null;
			}
			return `${this.value[0]}x${this.value[1]}`;
		},
		sliderMinLimits () {
			const width: number = this._get(this.field, 'display.sliders.min[0]') || this.MIN_DIMENSION;
			const height: number = this._get(this.field, 'display.sliders.min[1]') || this.MIN_DIMENSION;
			return { width, height };
		},
		sliderMaxLimits () {
			const width: number = this._get(this.field, 'display.sliders.max[0]') || this.MIN_DIMENSION;
			const height: number = this._get(this.field, 'display.sliders.max[1]') || this.MIN_DIMENSION;
			return { width, height };
		},
		computedWidthAndHeight () {
			if (this.isSelectDisplayWithValueFormat) {
				const { width, height } = (this.value && this.optionsMap[this.value]) || {
					width: this.sliderMinLimits.width,
					height: this.sliderMinLimits.height,
				};
				return { width, height };
			}
			if (_isArray(this.value)) {
				const width: number = this._get(this.value || [], '[0]') || this.sliderMinLimits.width;
				const height: number = this._get(this.value || [], '[1]') || this.sliderMinLimits.height;
				return { width, height };
			}
			return {
				width: this.sliderMinLimits.width,
				height: this.sliderMinLimits.height,
			};
		},
	},
	watch: {
		field: {
			immediate: true,
			handler (newVal: RunnitNodeField, oldVal: RunnitNodeField) {
				if (newVal !== oldVal) {
					this.optionsMap = _mapKeys(this._get(this.field, 'display.select.options') || [], 'value');
				}
			},
		},
	},
	methods: {
		onSelectFieldInput ({ width, height, value }) {
			if (this.isSelectDisplayWithValueFormat) {
				this.$emit('input', value);
			} else {
				this.emitWidthHeightArrayInput(width, height);
			}
		},
		onSliderWidthChange (width: number) {
			this.emitWidthHeightArrayInput(width, this.computedWidthAndHeight.height);
		},
		onSliderHeightChange (height: number) {
			this.emitWidthHeightArrayInput(this.computedWidthAndHeight.width, height);
		},
		emitWidthHeightArrayInput (width: number, height: number) {
			this.$emit(
				'input',
				[
					width > 0 ? width : this.sliderMinLimits.width,
					height > 0 ? height : this.sliderMinLimits.height,
				],
			);
		},
	},
	components: {
		BaseSlider,
		RunnitDynamicFieldLabelRow,
	},
});
