
import Vue from 'vue';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import { RunnitNodeDefToolType } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'RunnitTypeMultiSelect',
	props: {
		allowTeamCategorySelection: { type: Boolean, default: false },
		selectedTypes: { type: Array, default: () => [] },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
			allRunnitNodeDefToolTypeOptions: [
				RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
				RUNNIT_NODE_DEF_TOOL_TYPE.TEAM,
			],
		};
	},
	computed: {
		items () {
			return [
				{
					id: 1,
					label: 'Curated',
					toolType: RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				},
				{
					id: 2,
					label: 'Core',
					toolType: RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
				},
				...(this.allowTeamCategorySelection ? [{
					id: 3,
					label: 'Team',
					toolType: RUNNIT_NODE_DEF_TOOL_TYPE.TEAM,
				}] : []),
			];
		},
		selectedTypesMap () {
			return this.selectedTypes.reduce((map: Record<RunnitNodeDefToolType, boolean>, toolType: RunnitNodeDefToolType) => ({
				...map,
				[toolType]: true,
			}), {});
		},
		isSelectAllFilledIn () {
			return this.items.length <= this.selectedTypes.length;
		},
	},
	watch: {
		allowTeamCategorySelection: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal !== oldVal) {
					this.onSelectAll(newVal);
				}
			},
		},
	},
	methods: {
		onSelectAll (allowTeamCategorySelection: boolean) {
			this.$emit(
				'on-select',
				this.allRunnitNodeDefToolTypeOptions.filter(({ toolType }) => (
					toolType !== RUNNIT_NODE_DEF_TOOL_TYPE.TEAM || allowTeamCategorySelection
				)),
			);
		},
		handleOptionSelection (selectedItem) {
			let selectedTypes: RunnitNodeDefToolType[] = [...this.selectedTypes];

			if (this.isSelectAllFilledIn) {
				selectedTypes = [];
			}

			if (selectedTypes.includes(selectedItem.toolType)) {
				selectedTypes = selectedTypes.filter((toolType: RunnitNodeDefToolType) => toolType !== selectedItem.toolType);
			} else {
				selectedTypes.push(selectedItem.toolType);
			}

			if (!selectedTypes.length) {
				this.onSelectAll(this.allowTeamCategorySelection);
			} else {
				this.$emit('on-select', selectedTypes);
			}
		},
	},
});
